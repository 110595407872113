import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { pipe_fields_ids } from './pipe_fields_ids';
import { PipefyService } from 'src/app/services/pipefy.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { categorias, moedas, motivos_de_urgencia, subcategorias, tipo_de_operacao, tipo_de_lancamento, formas_pagamento, setores, quantidade_pedidos } from './index';
import { tiposDeNotasLancamentos } from './tipo-de-nota';
import { empresasLancamentos } from './empresas';
import { quantidadeItensLancamentos } from './quantidadeItens';
import { lista_cnpjs_empresa_nutrienLancamentos } from './lista_cnpjs_empresa_nutrien';
import { pipe_fields_idsLancamentos } from './pipe_fields_idsLancamentos';
import { tipos_de_notas_ipe } from './tipos-de-nota-ipe';
import { CommomServicesService } from 'src/app/services/commom-services.service';


@Component({
  selector: 'app-ipe-lancamentos-ocr-refat-new',
  templateUrl: './ipe-lancamentos-ocr-refat-new.component.html',
  styleUrls: ['./ipe-lancamentos-ocr-refat-new.component.css']
})
export class IpeLancamentosOcrRefatNewComponent implements OnInit {
  formulario!: FormGroup;

  email: string = '';

  dataAtual: any = '';

  listaFornecedores: any[] = [];
  listaFornecedoresOriginal: any[] = [];
  listaTomadores: any[] = [];
  listaTomadoresOriginal: any[] = [];
  // listaNumContratos: any[] = [];
  // listaAprovadores: any[] = [];

  categorias = categorias;
  moedas = moedas;
  motivos_de_urgencia = motivos_de_urgencia;
  subcategorias = subcategorias;
  tipo_de_operacao = tipo_de_operacao;
  tipo_de_lancamento = tipo_de_lancamento;
  formas_pagamento = formas_pagamento;
  setores = setores;
  quantidade_pedidos = quantidade_pedidos;
  tipos_de_notas_ipe = tipos_de_notas_ipe

  valorFaturaAlterado: any = 0;
  valorTotalNfAlterado: any = 0;

  caracteristicasChecked:any[] = []

  arquivosNpo: any[] = [];
  arquivosNpoUrl: any[] = [];

  arquivosXml: any[] = [];
  arquivosXmlUrl: any[] = [];

  arquivosNf: any[] = [];
  arquivosNfUrl: any[] = [];

  arquivosNfBoleto: any[] = [];
  arquivosNfBoletoUrl: any[] = [];

  arquivosBoleto: any[] = [];
  arquivosBoletoUrl: any[] = [];

  showMotivoUrgencia: boolean = false;
  showNumContrato: boolean = false;
  showAprovador: boolean = false;

  possuiBoletoChecked: boolean = false;

  chaveAcesso: string = '';

  fileName2: string = '';
  fileName3: string = '';

  resNotaJsonMost: any = '';
  resBoletoJsonMost: any = '';

  erroArquivosNfBoleto: boolean = false;

  exibirListaFornecedores: boolean = false;
  fornecedorSelecionado: any;
  exibeErroFornecedor: boolean = false;

  exibirListaTomadores: boolean = false;
  tomadorSelecionado: any;
  exibeErroTomador: boolean = false;

  error_counter: number = 0;
  erro: boolean = false



  // ********************** campos Lançamento de notas

  formularioLancamentos!: FormGroup;
  arquivosDeRateio: any[] = [];
  notas: any[] = [];
  boletos: any[] = [];
  xml: any[] = []
  documentacaoSuporte: any[] = [];
  nfProdutoOriginal: any[] = []

  listaDeItensDaPo: any[] = [];
  riscoSacado: boolean = false;
  dacteCorreto: boolean = false;
  notaComRateios: boolean = false;
  tiposDeNotas = tiposDeNotasLancamentos;
  empresas = empresasLancamentos;
  quantidadeDeItens = quantidadeItensLancamentos

  notaComRateioDeValoresString : string = ''
  riscoSacadoString: string = ''
  dacteCorretoString: string = ''

  arquivosDeRateioUrl: any[] = []
  notasUrl: any[] = []
  boletosUrl: any[] = []
  xmlUrl:any[]=[]
  documentacaoSuporteUrl: any[] = []
  nfProdutoOriginalUrl: any[] = []
  listaDeItensDaPoUrl: any [] = []



  emailApi: string = ''
  userNameApi: string = ''

  custoComplementar: boolean = false;
  debitoPosterior: boolean = false
  informacoesComplementaresPo:any[] = []

  listaEmpresasCnpjs = lista_cnpjs_empresa_nutrienLancamentos;


  constructor(private formBuilder: FormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService,
    private commomS: CommomServicesService)
    { }


  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      tipo_de_nota: ['',[Validators.maxLength(1000)]],
      caracteristicas: ['', [Validators.maxLength(1000)]],
      cpf_cnpj_fornecedor: ['', [Validators.maxLength(1000)]],
      cnpj_empresa_nutrien: ['', [Validators.maxLength(1000)]],
      // n_mero_do_contrato: ['', [Validators.required]],
      // aprovador: ['', [Validators.required]],
      e_mail_do_requisitante: ['', [Validators.required, Validators.maxLength(1000)]], //Validators.email
      // e_mail_do_fornecedor : ['',[Validators.email]],
      categoria: ['', [Validators.required, Validators.maxLength(1000)]],
      subcategoria_po: ['', [Validators.maxLength(1000)]],
      tipo_de_opera_o: ['', [Validators.maxLength(1000)]],
      migo: ['', [Validators.maxLength(1000)]],
      marque_todas_as_op_es_que_se_aplicam: ['', [Validators.maxLength(1000)]],
      tipo_de_lan_amento: ['', [Validators.maxLength(1000)]],
      // n_mero_do_processo: ['', []],
      boleto_para_pagamento: ['', [Validators.maxLength(1000)]],
      forma_de_pagamento: ['', [Validators.maxLength(1000)]],
      folha_servicos: ['', [Validators.maxLength(1000)]],
      // favorecido: ['', []],
      // valor_do_fi: ['', []],
      // setor: ['', []],
      data_de_emiss_o: ['', [Validators.maxLength(1000)]],
      data_de_vencimento: ['', [Validators.maxLength(1000)]],
      moeda: ['', [Validators.required, Validators.maxLength(1000)]],
      numero_da_nota_fiscal: ['', [Validators.maxLength(1000)]],
      valor_da_fatura: ['', [Validators.maxLength(1000)]],
      descri_o_breve: ['', [Validators.maxLength(1000)]],
      qtde_pedidos: ['', [Validators.maxLength(1000)]],

      numero_pedido_1: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_1: ['', [Validators.maxLength(1000)]],
      numero_pedido_2: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_2: ['', [Validators.maxLength(1000)]],
      numero_pedido_3: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_3: ['', [Validators.maxLength(1000)]],
      numero_pedido_4: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_4: ['', [Validators.maxLength(1000)]],
      numero_pedido_5: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_5: ['', [Validators.maxLength(1000)]],
      numero_pedido_6: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_6: ['', [Validators.maxLength(1000)]],
      numero_pedido_7: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_7: ['', [Validators.maxLength(1000)]],
      numero_pedido_8: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_8: ['', [Validators.maxLength(1000)]],
      numero_pedido_9: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_9: ['', [Validators.maxLength(1000)]],
      numero_pedido_10: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_10: ['', [Validators.maxLength(1000)]],
      lista_pedidos: ['', [Validators.maxLength(1000)]],

      anexo_xml: ['', []],
      anexo_nota_fiscal_ou_boleto: ['', []],
      anexo_nota_fiscal_documento_boleto_se_houver: ['', []],
      anexo_npo_modelo_excel: ['', []],
      anexo_boleto_ipe: ['', []],

      inputSearchFornecedor: ['', [Validators.maxLength(1000)]],
      inputSearchTomador: ['', [Validators.maxLength(1000)]],

      n_mero_afe: ['',[Validators.maxLength(1000)]]
    })

    this.formularioLancamentos = this.formBuilder.group({
      nome: ['', [Validators.required,Validators.maxLength(1000)]],
      email: ['', [Validators.required, Validators.email]],
      risco_sacado: ['', []],
      qual_a_quantidade_de_cte:['',[]],
      tipo_de_nota: ['', [Validators.required]],
      conta_contabil:['',[]],
      documento_do_fornecedor: ['', [Validators.required]],
      cpf_do_fornecedor: ['', []],
      cnpj_fornecedor: ['', []],
      nome_do_fornecedor: ['', [Validators.required]],
      cnpj_da_empresa_nutrien: ['', [Validators.required]],
      nome_empresa_nutrien: ['', [Validators.required]],
      nro_nota: ['', [Validators.required]],
      data_de_envio_de_nf: ['', [Validators.required]],
      valor_total_da_nf: ['', [Validators.required]],
      nota_com_rasteio_de_valores: [, []],
      arquivo_de_rateio: ['', []],
      data_de_vencimento_da_nota: ['', [Validators.required]],
      departamento: ['', []],
      numero_de_pedido: ['', []],
      quantidade_de_itens_do_pedido: ['', []],
      numero_do_item_da_po:['',[]],
      lista_de_itens_da_po:['',[]],
      centro_de_custo: ['', []],
      anexo_nota_ou_boleto: ['', [Validators.required]],
      anexo_boleto: ['', []],
      anexo_xml: ['', []],
      anexo_documentacao_suporte: ['', []],
      nota_para_pagamento_fora_da_politica: ['', []],
      nota_para_pagamento_urgente: ['', []],
      motivo_da_excecao: ['',[]],
      observacao: ['', [Validators.maxLength(1000)]],
      informacoes_complementares_da_po:['',[]],

      nf_do_produto_original:['',[]],
      tipo_de_custo_planejado:['',[]],
      n_mero_de_cte:['',[]],
      opex_e_capex:['',[]],
      tipo_de_importa_o:['',[]],
      tipo_de_pagamento:['',[]],

      n_mero_afe:['',[]],


      certeza_que_incluiu_o_arquivo_conforme_orienta_o : ['',[]]

    })


    this.getUserEmail();
    this.formataValorDecimal('0');
    // this.getListaFornecedor();
    // this.getListaTomador();
    this.getDataAtual();

    this.emailApi = this.userInfo.getUserEmail();
    this.userNameApi = this.userInfo.getName()

    this.formularioLancamentos.get('email')?.setValue(this.emailApi)
    this.formularioLancamentos.get('nome')?.setValue(this.userNameApi)


    if (this.formularioLancamentos.getRawValue().email != '') {
      // this.formularioLancamentos.controls['email'].disable()
    }
    if (this.formularioLancamentos.getRawValue().email == undefined) {
      // this.formularioLancamentos.controls['email'].enable()
      this.formularioLancamentos.get('email')?.setValue('')

    }

    if (this.formularioLancamentos.getRawValue().nome != '') {
      // this.formularioLancamentos.controls['nome'].disable()
    }
    if (this.formularioLancamentos.getRawValue().nome == undefined) {
      // this.formularioLancamentos.controls['nome'].enable()
      this.formularioLancamentos.get('nome')?.setValue('')
    }
  }


  enviarFormulario() {
    console.log('subcat', this.formulario.getRawValue().subcategoria_po);
    console.log('tipolanc', this.formulario.getRawValue().tipo_de_lan_amento);
    console.log('categ', this.formulario.getRawValue().categoria);
    console.log('this.arquivosNf', this.arquivosNf);
    console.log('this.arquivosNfUrl', this.arquivosNfUrl);
    console.log('this.arquivosBoleto', this.arquivosBoleto);
    console.log('this.arquivosBoletoUrl', this.arquivosBoletoUrl);

    //IPE
    if
    (this.formulario.getRawValue().tipo_de_nota !== "Frete/CTE")
    // (
    //     (
    //       this.formulario.getRawValue().categoria == 'NPO' &&
    //       this.formulario.getRawValue().tipo_de_lan_amento !== 'Benefícios'
    //     ) ||
    //     (
    //       this.formulario.getRawValue().subcategoria_po !== '' &&
    //       this.formulario.getRawValue().subcategoria_po !== 'Frete ' &&
    //       this.formulario.getRawValue().subcategoria_po !== 'Benefícios' &&
    //       this.formulario.getRawValue().subcategoria_po !== 'Importação' &&
    //       this.formulario.getRawValue().subcategoria_po !== 'Nota de Crédito'
    //     )
    //   )
      {
      console.log("entrei em IPE")
      this.spinner.show()


      const criarBody = async () => {
        console.log('this.arquivosNf: ', this.arquivosNf)
        console.log('this.arquivosNfUrl: ', this.arquivosNfUrl)

        console.log('this.arquivosNf length: ', this.arquivosNf.length)
        console.log('this.arquivosNfUrl length: ', this.arquivosNfUrl.length)

        console.log('this.arquivosBoleto length: ', this.arquivosBoleto.length)
        console.log('this.arquivosBoletoUrl length: ', this.arquivosBoletoUrl.length)

        await this.getPipesUrlArquivoBoleto(this.arquivosBoleto);
        console.log('function getPipesUrlArquivoBoleto')
        if(this.arquivosBoleto.length >0){
          console.log('arquivosBoleto.length: ', this.arquivosBoleto.length)
          console.log('arquivosBoletoUrl.length: ', this.arquivosBoletoUrl.length)
          while (this.arquivosBoleto.length != this.arquivosBoletoUrl.length) {
            console.log("esperando....")
            console.log(this.arquivosBoletoUrl.length)
            console.log('inicio delay getPipesUrlArquivoBoleto')
            await this.delay(300);
            console.log(this.arquivosBoletoUrl.length)
            console.log('termino delay getPipesUrlArquivoBoleto')
            if(this.erro){
              console.log('this.erro getPipesUrlArquivoBoleto', this.erro)
              break;
            }
          }
          if(this.erro){
            console.log('this.erro getPipesUrlArquivoBoleto', this.erro)
            return
          }
        }

        if(this.arquivosBoleto.length>0){
          var arquivosBoletoUrl2 = ''
          arquivosBoletoUrl2 = '['
          for(var i = 0; i < this.arquivosBoletoUrl.length ; i++){
            arquivosBoletoUrl2 =  arquivosBoletoUrl2+'"'+this.arquivosBoletoUrl[i]+'",'
          }
          arquivosBoletoUrl2 = arquivosBoletoUrl2.slice(0, -1)
          arquivosBoletoUrl2 = arquivosBoletoUrl2+"]"

        }else{
          arquivosBoletoUrl2 =''
        }


        await this.getPipesUrlArquivoNpo(this.arquivosNpo);
        console.log('function getPipesUrlArquivoNpo')
        if(this.arquivosNpo.length >0){
          console.log('arquivosNpo.length >0')
          while (this.arquivosNpo.length != this.arquivosNpoUrl.length) {
            console.log("esperando....")
            console.log('inicio delay getPipesUrlArquivoNpo')
            await this.delay(300);
            console.log('termino delay getPipesUrlArquivoNpo')
            if(this.erro){
              console.log('this.erro arquivosNpo', this.erro)
              break;
            }
          }
          if(this.erro){
            console.log('this.erro arquivosXml', this.erro)
            return
          }
        }

        if(this.arquivosNpo.length>0){
          var arquivosNpoUrl2 = ''
          arquivosNpoUrl2 = '['
          for(var i = 0; i < this.arquivosNpoUrl.length ; i++){
            arquivosNpoUrl2 = arquivosNpoUrl2+'"'+this.arquivosNpoUrl[i]+'",'
          }
          arquivosNpoUrl2 = arquivosNpoUrl2.slice(0, -1)
          arquivosNpoUrl2 = arquivosNpoUrl2+"]"

        }else{
          arquivosNpoUrl2 =''
        }

        await this.getPipesUrlArquivoXml(this.arquivosXml);
        console.log('function getPipesUrlArquivoXml')
        if(this.arquivosXml.length >0){
          console.log('arquivosXml.length >0')
          while (this.arquivosXml.length != this.arquivosXmlUrl.length) {
            console.log("esperando....")
            console.log('inicio delay getPipesUrlArquivoXml')
            await this.delay(300);
            console.log('termino delay getPipesUrlArquivoXml')
            if(this.erro){
              console.log('this.erro arquivosXml', this.erro)
              break;
            }
          }
          if(this.erro){
            console.log('this.erro arquivosXml', this.erro)
            return
          }
        }

        if(this.arquivosXml.length>0){
          var arquivosXmlUrl2 = ''
          arquivosXmlUrl2 = '['
          for(var i = 0; i < this.arquivosXmlUrl.length ; i++){
            arquivosXmlUrl2 =  arquivosXmlUrl2+'"'+this.arquivosXmlUrl[i]+'",'
          }
          arquivosXmlUrl2 = arquivosXmlUrl2.slice(0, -1)
          arquivosXmlUrl2 = arquivosXmlUrl2+"]"

        }else{
          arquivosXmlUrl2 =''
        }

        await this.getPipesUrlArquivoNf(this.arquivosNf);
        console.log('function getPipesUrlArquivoNf')
        if(this.arquivosNf.length >0){
          console.log('arquivosNf.length: ', this.arquivosNf.length)
          console.log('arquivosNfUrl.length: ', this.arquivosNfUrl.length)
          while (this.arquivosNf.length != this.arquivosNfUrl.length) {
            // console.log("esperando....")
            // console.log(this.arquivosNfUrl.length)
            // console.log('this.arquivosNfUrl', this.arquivosNfUrl);

            // console.log('inicio delay getPipesUrlArquivoNf')
            await this.delay(300);
            // console.log(this.arquivosNfUrl.length)
            // console.log('termino delay getPipesUrlArquivoNf')
            if(this.erro){
              console.log('this.erro getPipesUrlArquivoNf', this.erro)
              break;
            }
          }
          if(this.erro){
            console.log('this.erro getPipesUrlArquivoNf', this.erro)
            return
          }
        }

        if(this.arquivosNf.length>0){
          var arquivosNfUrl2 = ''
          arquivosNfUrl2 = '['
          for(var i = 0; i < this.arquivosNfUrl.length ; i++){
            arquivosNfUrl2 =  arquivosNfUrl2+'"'+this.arquivosNfUrl[i]+'",'
          }
          arquivosNfUrl2 = arquivosNfUrl2.slice(0, -1)
          arquivosNfUrl2 = arquivosNfUrl2+"]"

        }else{
          arquivosNfUrl2 =''
        }

        await this.getPipesUrlArquivoNfBoleto(this.arquivosNfBoleto);
        console.log('function getPipesUrlArquivoNfBoleto')
        if(this.arquivosNfBoleto.length >0){
          console.log('arquivosNfBoleto.length: ', this.arquivosNfBoleto.length)
          console.log('arquivosNfBoletoUrl.length: ', this.arquivosNfBoletoUrl.length)
          while (this.arquivosNfBoleto.length != this.arquivosNfBoletoUrl.length) {
            console.log("esperando....")
            console.log('inicio delay getPipesUrlArquivoNfBoleto')
            await this.delay(300);
            console.log('termino delay getPipesUrlArquivoNfBoleto')
            if(this.erro){
              console.log('this.erro getPipesUrlArquivoNfBoleto', this.erro)
              break;
            }
          }
          if(this.erro){
            console.log('this.erro getPipesUrlArquivoNfBoleto', this.erro)
            return
          }
        }

        if(this.arquivosNfBoleto.length>0){
          var arquivosNfBoletoUrl2 = ''
          arquivosNfBoletoUrl2 = '['
          for(var i = 0; i < this.arquivosNfBoletoUrl.length ; i++){
            arquivosNfBoletoUrl2 =  arquivosNfBoletoUrl2+'"'+this.arquivosNfBoletoUrl[i]+'",'
          }
          arquivosNfBoletoUrl2 = arquivosNfBoletoUrl2.slice(0, -1)
          arquivosNfBoletoUrl2 = arquivosNfBoletoUrl2+"]"

        }else{
          arquivosNfBoletoUrl2 =''
        }



        console.log('iniciar fields')
        let tipo_de_nota = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_nota);

        let caracteristicas = '['+this.caracteristicasChecked+']';
        let cpf_cnpj_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().cpf_cnpj_fornecedor);
        let cnpj_empresa_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().cnpj_empresa_nutrien);
        let email_requisitante = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_do_requisitante) + '@nutrien.com';
        // let e_mail_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_do_fornecedor)
        let categoria = this.trataInput.removeCaracters(this.formulario.getRawValue().categoria);
        let subcategoria_po = this.trataInput.removeCaracters(this.formulario.getRawValue().subcategoria_po);
        let tipo_operacao = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_opera_o);
        let migo = this.trataInput.removeCaracters(this.formulario.getRawValue().migo);
        let marque_todas_opcoes_aplicam = this.formulario.getRawValue().marque_todas_as_op_es_que_se_aplicam == true ? 'Serviço ou Material é um Ativo Fixo' : '';
        let tipo_lancamento = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_lan_amento);
        // let numero_processo = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_do_processo);
        let boleto_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().boleto_para_pagamento);
        let forma_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().forma_de_pagamento);
        let folha_servicos = this.trataInput.removeCaracters(this.formulario.getRawValue().folha_servicos);
        // let favorecido = this.trataInput.removeCaracters(this.formulario.getRawValue().favorecido);
        // let valor_fi = this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_fi);
        // let setor = this.trataInput.removeCaracters(this.formulario.getRawValue().setor);
        let data_emissao = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_emiss_o);
        let data_vencimento = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento);
        let moeda = this.trataInput.removeCaracters(this.formulario.getRawValue().moeda);
        let numero_nota_fiscal = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_da_nota_fiscal);
        let valor_fatura = this.valorFaturaAlterado;
        let descricao_breve = this.trataInput.removeCaracters(this.formulario.getRawValue().descri_o_breve);
        let qtde_pedidos = this.trataInput.removeCaracters(this.formulario.getRawValue().qtde_pedidos);
        let numero_pedido_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_1);
        let numero_linha_pedido_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_1);
        let numero_pedido_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_2);
        let numero_linha_pedido_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_2);
        let numero_pedido_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_3);
        let numero_linha_pedido_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_3);
        let numero_pedido_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_4);
        let numero_linha_pedido_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_4);
        let numero_pedido_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_5);
        let numero_linha_pedido_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_5);
        let numero_pedido_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_6);
        let numero_linha_pedido_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_6);
        let numero_pedido_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_7);
        let numero_linha_pedido_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_7);
        let numero_pedido_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_8);
        let numero_linha_pedido_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_8);
        let numero_pedido_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_9);
        let numero_linha_pedido_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_9);
        let numero_pedido_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_pedido_10);
        let numero_linha_pedido_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_10);
        let lista_pedidos = this.trataInput.removeCaracters(this.formulario.getRawValue().lista_pedidos);
        let n_mero_afe = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_afe);



        let pipe_fields = pipe_fields_ids
        // if(
        //     this.formulario.getRawValue().tipo_de_nota !== "Material Compras e aquisição"
        //     &&  this.formulario.getRawValue().tipo_de_nota !== "Serviço"
        //     &&  this.formulario.getRawValue().tipo_de_nota !== "Beneficios"
        //     &&  this.formulario.getRawValue().tipo_de_nota !== "Escrituração - Não gera pagamento"
        //     &&  this.formulario.getRawValue().tipo_de_nota !== "Importação"
        //     &&  this.formulario.getRawValue().tipo_de_nota !== "Sem valor fiscal"
        // ){
        //   console.log("entrei em diferente de serviço")
        var fields_values = [
          tipo_de_nota,
          caracteristicas,
          this.fornecedorSelecionado.cardId,
          cpf_cnpj_fornecedor,
          this.tomadorSelecionado.cardId,
          cnpj_empresa_nutrien,
          email_requisitante,
          // e_mail_do_fornecedor,
          categoria,
          subcategoria_po,
          tipo_operacao,
          migo,
          tipo_lancamento,
          // numero_processo,
          // arquivosNfUrl2,
          // arquivosXmlUrl2,
          // arquivosNfBoletoUrl2,
          // arquivosNpoUrl2,
          marque_todas_opcoes_aplicam,
          data_emissao,
          data_vencimento,
          moeda,
          numero_nota_fiscal,
          valor_fatura,
          boleto_pagamento,
          forma_pagamento,
          folha_servicos,
          // favorecido,
          // valor_fi,
          // setor,
          descricao_breve,
          qtde_pedidos,
          numero_pedido_1,
          numero_linha_pedido_1,
          numero_pedido_2,
          numero_linha_pedido_2,
          numero_pedido_3,
          numero_linha_pedido_3,
          numero_pedido_4,
          numero_linha_pedido_4,
          numero_pedido_5,
          numero_linha_pedido_5,
          numero_pedido_6,
          numero_linha_pedido_6,
          numero_pedido_7,
          numero_linha_pedido_7,
          numero_pedido_8,
          numero_linha_pedido_8,
          numero_pedido_9,
          numero_linha_pedido_9,
          numero_pedido_10,
          numero_linha_pedido_10,
          lista_pedidos,
          this.arquivosNfUrl,
          this.arquivosXmlUrl,
          this.arquivosNfBoletoUrl,
          this.arquivosNpoUrl,
          this.arquivosBoletoUrl,
          n_mero_afe,
          this.chaveAcesso,
          this.emailApi
        ]

        let pipe_id = "303800100"
        console.log('fields_values: ', fields_values)

        this.pipefy.criaBody(pipe_fields,fields_values,pipe_id)
        console.log('iniciar criação do card')
        this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
          // console.log(res)
          this.spinner.hide()

          if (res.data.result_creation_card.id != undefined) {
            let card_id = res.data.result_creation_card.id
            Swal.fire({
              title: 'Chamado aberto com Sucesso!',
              text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
              // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
            })
            this.resetform();
            this.arquivosXml = [];
            this.arquivosXmlUrl = [];

            this.arquivosNf = [];
            this.arquivosNfUrl = [];

            this.arquivosNfBoleto = [];
            this.arquivosNfBoletoUrl = [];

            this.arquivosNpo = [];
            this.arquivosNpoUrl = [];

            this.arquivosBoleto = [];
            this.arquivosBoletoUrl = [];
          }

          if (res.data.result_creation_card.errors != undefined) {
            var erro = res.data.result_creation_card.errors[0].message
            console.log(erro);
            Swal.fire({
              title: 'Desculpe!',
              html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação.</span></br>',
              text: erro,
              icon: 'error',
              confirmButtonColor: '#88D600',
            })

            this.arquivosXmlUrl = [];
            this.arquivosNfUrl = [];
            this.arquivosNfBoletoUrl = [];
            this.arquivosNpoUrl = [];
            this.arquivosBoletoUrl = [];



            let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Finanças', "Finanças - criação de card -- Solicitação de Lançamento de Notas", erro, 'Portal NBS Error', 'incidente@portal.com',
              'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.email, this.userInfo.getVersion()]

            var pipe_fields_incidentes_values2 = []
            for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
              pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
            }
            // console.log(pipe_fields_incidentes_values2)

            let pipe_incidentes_id = '302487533'

            this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
              // console.log(res)
            })
          }
        },(error: any) => {
          this.spinner.hide()
          // console.log(error.status)
          console.log(error)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosXmlUrl = [];
            this.arquivosNfUrl = [];
            this.arquivosNfBoletoUrl = [];
            this.arquivosNpoUrl = [];
            this.arquivosBoletoUrl = [];
          }
        })

      }
      criarBody()
    }

    //LANÇAMENTO DE NOTAS
    if
    (this.formulario.getRawValue().tipo_de_nota == "Frete/CTE")

    // (
    //   (
    //     this.formulario.getRawValue().categoria == 'NPO' &&
    //     this.formulario.getRawValue().tipo_de_lan_amento == 'Benefícios'
    //   ) ||
    //   (
    //     this.formulario.getRawValue().subcategoria_po == 'Frete ' ||
    //     this.formulario.getRawValue().subcategoria_po == 'Benefícios' ||
    //     this.formulario.getRawValue().subcategoria_po == 'Importação' ||
    //     this.formulario.getRawValue().subcategoria_po == 'Nota de Crédito'
    //   )
    // )
    {
      console.log("Entrei em LANÇAMENTO")
      this.spinner.show()

      const criarBody = async () => {

        await this.getPipesUrlArquivoRateio(this.arquivosDeRateio);
        if(this.arquivosDeRateio.length >0){
          while (this.arquivosDeRateio.length != this.arquivosDeRateioUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
            if(this.erro){
              break;
            }
          }
          if(this.erro){
            return
          }
        }

        if(this.arquivosDeRateio.length>0){
          var arquivosDeRateioUrl2 = ''
          arquivosDeRateioUrl2 = '['
          for(var i = 0; i < this.arquivosDeRateioUrl.length ; i++){
            arquivosDeRateioUrl2 = arquivosDeRateioUrl2+'"'+this.arquivosDeRateioUrl[i]+'",'
          }
          arquivosDeRateioUrl2 = arquivosDeRateioUrl2.slice(0, -1)
          arquivosDeRateioUrl2 = arquivosDeRateioUrl2+"]"

        }else{
          arquivosDeRateioUrl2 =''
        }

        await this.getPipesUrlNota(this.notas);
        // console.log(this.notas)
        // console.log(this.notas.length)
        // console.log(this.notasUrl)

        if(this.notas.length >0){
          while (this.notas.length != this.notasUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
            if(this.erro){
              break;
            }
          }
          if(this.erro){
            return
          }
        }


        if(this.notas.length>0){
          var notasUrl2 = ''
          notasUrl2 = '['
          for(var i = 0; i < this.notasUrl.length ; i++){
            notasUrl2 =  notasUrl2+'"'+this.notasUrl[i]+'",'
          }
          notasUrl2 = notasUrl2.slice(0, -1)
          notasUrl2 = notasUrl2+"]"

        }else{
          notasUrl2 =''
        }

        await this.getPipesUrlBoleto(this.boletos);
        // console.log(this.boletos)
        // console.log(this.boletos.length)
        // console.log(this.boletosUrl)

        if(this.boletos.length >0){
          while (this.boletos.length != this.boletosUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
            if(this.erro){
              break;
            }
          }
          if(this.erro){
            return
          }
        }

        if(this.boletos.length>0){
          var boletosUrl2 = ''
          boletosUrl2 = '['
          for(var i = 0; i < this.boletosUrl.length ; i++){
            boletosUrl2 =  boletosUrl2+'"'+this.boletosUrl[i]+'",'
          }
          boletosUrl2 = boletosUrl2.slice(0, -1)
          boletosUrl2 = boletosUrl2+"]"

        }else{
          boletosUrl2 =''
        }

        await this.getPipesUrlXML(this.xml);
        // console.log(this.xml)
        // console.log(this.xml.length)
        // console.log(this.xmlUrl)

        if(this.xml.length >0){
          while (this.xml.length != this.xmlUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
            if(this.erro){
              break;
            }
          }
          if(this.erro){
            return
          }
        }

        if(this.xml.length>0){
          var xmlUrl2 = ''
          xmlUrl2 = '['
          for(var i = 0; i < this.xmlUrl.length ; i++){
            xmlUrl2 =  xmlUrl2+'"'+this.xmlUrl[i]+'",'
          }
          xmlUrl2 = xmlUrl2.slice(0, -1)
          xmlUrl2 = xmlUrl2+"]"

        }else{
          xmlUrl2 =''
        }


        await this.getPipesUrlDocumentacaoSuporte(this.documentacaoSuporte);
        if(this.documentacaoSuporte.length >0){
          while (this.documentacaoSuporte.length != this.documentacaoSuporteUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
            if(this.erro){
              break;
            }
          }
          if(this.erro){
            return
          }
        }

        if(this.documentacaoSuporte.length>0){
          var documentacaoSuporteUrl2 = ''
          documentacaoSuporteUrl2 = '['
          for(var i = 0; i < this.documentacaoSuporteUrl.length ; i++){
            documentacaoSuporteUrl2 =  documentacaoSuporteUrl2+'"'+this.documentacaoSuporteUrl[i]+'",'
          }
          documentacaoSuporteUrl2 = documentacaoSuporteUrl2.slice(0, -1)
          documentacaoSuporteUrl2 = documentacaoSuporteUrl2+"]"

        }else{
          documentacaoSuporteUrl2 =''
        }

        await this.getPipesUrlNfProdutoOriginal(this.nfProdutoOriginal);
        if(this.nfProdutoOriginal.length >0){
          while (this.nfProdutoOriginal.length != this.nfProdutoOriginalUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
            if(this.erro){
              break;
            }
          }
          if(this.erro){
            return
          }
        }

        if(this.nfProdutoOriginal.length>0){
          var nfProdutoOriginalUrl2 = ''
          nfProdutoOriginalUrl2 = '['
          for(var i = 0; i < this.nfProdutoOriginalUrl.length ; i++){
            nfProdutoOriginalUrl2 =  nfProdutoOriginalUrl2+'"'+this.nfProdutoOriginalUrl[i]+'",'
          }
          nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2.slice(0, -1)
          nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2+"]"

        }else{
          nfProdutoOriginalUrl2 =''
        }

        await this.getPipesUrlListaItensDaPo(this.listaDeItensDaPo);
        if(this.listaDeItensDaPo.length >0){
          while (this.listaDeItensDaPo.length != this.listaDeItensDaPoUrl.length) {
            // console.log("esperando....")
            await this.delay(300);
            if(this.erro){
              break;
            }
          }
          if(this.erro){
            return
          }
        }

        if(this.listaDeItensDaPo.length>0){
          var listaDeItensDaPoUrl2 = ''
          listaDeItensDaPoUrl2 = '['
          for(var i = 0; i < this.listaDeItensDaPoUrl.length ; i++){
            listaDeItensDaPoUrl2 =  listaDeItensDaPoUrl2+'"'+this.listaDeItensDaPoUrl[i]+'",'
          }
          listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2.slice(0, -1)
          listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2+"]"

        }else{
          listaDeItensDaPoUrl2 =''
        }


      let nome = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().nome)
      let email = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().email)
      let risco_sacado = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().risco_sacado)
      let tipo_de_nota = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().tipo_de_nota)
      let qual_a_quantidade_de_cte = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().qual_a_quantidade_de_cte)
      let conta_contabil = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().conta_contabil)
      let documento_do_fornecedor = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().documento_do_fornecedor)
      let cpf_do_fornecedor = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().cpf_do_fornecedor)
      let cnpj_fornecedor = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().cnpj_fornecedor)
      let nome_do_fornecedor = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().nome_do_fornecedor)
      let cnpj_da_empresa_nutrien = this.formularioLancamentos.getRawValue().cnpj_da_empresa_nutrien.replaceAll('.', '').replaceAll("/", "").replaceAll("-", "");
      let nome_empresa_nutrien = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().nome_empresa_nutrien)
      let nro_nota = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().nro_nota)
      let data_de_envio_de_nf = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().data_de_envio_de_nf)
      let valor_total_da_nf = this.valorTotalNfAlterado;
      let data_de_vencimento_da_nota = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().data_de_vencimento_da_nota))
      let departamento = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().departamento)
      let numero_de_pedido = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().numero_de_pedido)
      let quantidade_de_itens_do_pedido = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().quantidade_de_itens_do_pedido)
      let numero_do_item_da_po = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().numero_do_item_da_po)

      let centro_de_custo = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().centro_de_custo)
      let nota_para_pagamento_fora_da_politica = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().nota_para_pagamento_fora_da_politica)
      let nota_para_pagamento_urgente = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().nota_para_pagamento_urgente)
      let motivo_da_excecao = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().motivo_da_excecao)
      let observacao = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().observacao)
      let informacoes_complementares_da_po = '['+this.informacoesComplementaresPo+']'

      let tipo_de_custo_planejado = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().tipo_de_custo_planejado)
      let n_mero_de_cte = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().n_mero_de_cte)
      let opex_e_capex = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().opex_e_capex)
      let tipo_de_importa_o = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().tipo_de_importa_o)
      let tipo_de_pagamento = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().tipo_de_pagamento)

      let n_mero_afe = this.trataInput.removeCaracters(this.formularioLancamentos.getRawValue().n_mero_afe)


      // console.log('departamento', departamento)

      let pipe_fields = pipe_fields_idsLancamentos
      let fields_values = [
      nome,
      email,
      this.riscoSacadoString,
      tipo_de_nota,
      qual_a_quantidade_de_cte,
      conta_contabil,
      documento_do_fornecedor,
      cpf_do_fornecedor,
      cnpj_fornecedor
      ,nome_do_fornecedor,
      cnpj_da_empresa_nutrien,
      nome_empresa_nutrien,
      nro_nota,
      data_de_envio_de_nf,
      valor_total_da_nf,
      this.notaComRateioDeValoresString,
      // arquivosDeRateioUrl2,
      data_de_vencimento_da_nota,
      departamento,numero_de_pedido,
      quantidade_de_itens_do_pedido,
      numero_do_item_da_po,
      // listaDeItensDaPoUrl2,
      centro_de_custo,
      // notasUrl2,
      this.dacteCorretoString,
      // documentacaoSuporteUrl2,
      nota_para_pagamento_fora_da_politica,
      nota_para_pagamento_urgente,
      motivo_da_excecao,
      observacao,
      informacoes_complementares_da_po,
      // boletosUrl2,
      // xmlUrl2,
      // nfProdutoOriginalUrl2,
      tipo_de_custo_planejado,
      n_mero_de_cte,
      opex_e_capex,
      tipo_de_importa_o,
      tipo_de_pagamento,
      n_mero_afe,

      this.arquivosDeRateioUrl,
      this.listaDeItensDaPoUrl,
      this.notasUrl,
      this.documentacaoSuporteUrl,
      this.xmlUrl,
      this.boletosUrl,
      this.nfProdutoOriginalUrl


    ]

    // hml
    let pipe_id = ""



      this.pipefy.criaBody(pipe_fields,fields_values,pipe_id)


      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetformLancamentos()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação.</span></br>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []



          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Finanças', "Finanças - criação de card -- Solicitação de Lançamento de Notas", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.emailApi, this.userInfo.getVersion()]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      },(error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []
        }
      })

      }
      criarBody()
    }
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  saveArquivoNpo(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosNpo.push(files[i])
    }
  }

  removeFileArquivoNpo(file: File) {
    const index: number = this.arquivosNpo.indexOf(file);
    this.arquivosNpo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosNpo.length == 0){
      this.formulario.controls['anexo_npo_modelo_excel'].markAsTouched()
      this.formulario.get('anexo_npo_modelo_excel')?.setValue('')
    }
  }

  async getPipesUrlArquivoNpo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        } (async () => {
          let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

          var fileUrlTemp = "";
          var startIndex = 0;

          if (urlResposta.indexOf("orgs") > 0) {
            startIndex = urlResposta.indexOf("orgs/");
          } else {
            startIndex = urlResposta.indexOf("uploads/");
          }

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.arquivosNpoUrl.push(fileUrlTemp)
       })();
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosNpoUrl = []
          }
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoXml(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosXml.push(files[i])
    }
  }

  removeFileArquivoXml(file: File) {
    const index: number = this.arquivosXml.indexOf(file);
    this.arquivosXml.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosXml.length == 0){
      this.formulario.controls['anexo_xml'].markAsTouched()
      this.formulario.get('anexo_xml')?.setValue('')
    }
  }

  async getPipesUrlArquivoXml(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        } (async () => {
          let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

          var fileUrlTemp = "";
          var startIndex = 0;

          if (urlResposta.indexOf("orgs") > 0) {
            startIndex = urlResposta.indexOf("orgs/");
          } else {
            startIndex = urlResposta.indexOf("uploads/");
          }

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.arquivosXmlUrl.push(fileUrlTemp)
       })();
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosXmlUrl = []
          }
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoNf(event: any): void {
    var files = event.target.files
    console.log('entrei saveArquivoNf')
    for (var i = 0; i < files.length; i++) {
      this.arquivosNf.push(files[i])
      console.log(this.arquivosNf)
    }


  }

  removeFileArquivoNf(file: File) {
    const index: number = this.arquivosNf.indexOf(file);
    this.arquivosNf.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosNf.length == 0){
      this.formulario.controls['anexo_nota_fiscal_ou_boleto'].markAsTouched()
      this.formulario.get('anexo_nota_fiscal_ou_boleto')?.setValue('')
    }
    this.clearFieldsOCR();
  }

  async getPipesUrlArquivoNf(files: any) {
    console.log('entrou url')
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      if (fileName == this.fileName3) {
        Swal.fire({
          title: 'Ops!',
          text: 'Os arquivos Anexo Nota Fiscal/Documento e Anexo Boleto devem ser diferentes, por favor verifique e tente novamente.',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        this.formulario.controls['anexo_boleto_ipe']?.setValue('');
        this.formulario.controls['anexo_boleto_ipe'].updateValueAndValidity;

        this.arquivosBoleto = [];
        this.arquivosBoletoUrl = [];

        this.erroArquivosNfBoleto = true;
        this.spinner.hide();
        return
      } else {
        const reader2 = new FileReader();
        reader2.readAsDataURL(fileContent);
        reader2.onabort = () => {
          console.log('aborted')
        }
        reader2.onerror = (err) => {
          console.log('error:', err)
          this.spinner.hide();
        }
        reader2.onprogress = function (event) {
          if (event.lengthComputable) {
            let percentLoaded = Math.round((event.loaded / event.total) * 100);
            console.log('onprogress: ' + percentLoaded + '%');
          }
        }
        reader2.onload = () => {
          fileContent = reader2.result?.toString()
          var fileContentStart = fileContent.indexOf(",")
          console.log('fileContent:' , fileContent)

          fileContent = fileContent.substring(fileContentStart + 1)
          fileContent = fileContent.toString()

          var binaryString = window.atob(fileContent);
          var byteArray = new Uint8Array(binaryString.length);
          for (var i = 0; i < binaryString.length; i++) {
            byteArray[i] += binaryString.charCodeAt(i);
          } (async () => {
            console.log('urlResposta:' , byteArray)

            let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

            var fileUrlTemp = "";
            var startIndex = 0;

            if (urlResposta.indexOf("orgs") > 0) {
              startIndex = urlResposta.indexOf("orgs/");
            } else {
              startIndex = urlResposta.indexOf("uploads/");
            }

            var endIndex = urlResposta.indexOf("?");
            fileUrlTemp = urlResposta.substring(startIndex, endIndex);

            console.log('aqui', fileUrlTemp)

            this.arquivosNfUrl.push(fileUrlTemp)
            console.log(this.arquivosNfUrl)
         })();
        },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            console.log(error.status)
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.arquivosNfUrl = []
            }
        }
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoNfBoleto(event: any): void {
    var files = event.target.files;
    console.log('entrei saveArquivoNfBoleto')

    for (var i = 0; i < files.length; i++) {
      this.arquivosNfBoleto.push(files[i])
    }
  }

  removeFileArquivoNfBoleto(file: File) {
    const index: number = this.arquivosNfBoleto.indexOf(file);
    this.arquivosNfBoleto.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosNfBoleto.length == 0){
      this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].markAsTouched()
      this.formulario.get('anexo_nota_fiscal_documento_boleto_se_houver')?.setValue('')
    }
    this.clearFieldsOCR();
  }

  async getPipesUrlArquivoNfBoleto(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        } (async () => {
          let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

          var fileUrlTemp = "";
          var startIndex = 0;

          if (urlResposta.indexOf("orgs") > 0) {
            startIndex = urlResposta.indexOf("orgs/");
          } else {
            startIndex = urlResposta.indexOf("uploads/");
          }

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.arquivosNfBoletoUrl.push(fileUrlTemp)
       })();
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosNfBoletoUrl = []
          }
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  saveArquivoBoleto(event: any): void {
    var files = event.target.files;
    console.log('entrei saveArquivoBoleto')

    for (var i = 0; i < files.length; i++) {
      this.arquivosBoleto.push(files[i])
      console.log(this.arquivosBoleto)
    }
  }

  removeFileArquivoBoleto(file: File) {
    const index: number = this.arquivosBoleto.indexOf(file);
    this.arquivosBoleto.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.arquivosBoleto.length == 0){
      this.formulario.controls['anexo_boleto_ipe'].markAsTouched()
      this.formulario.get('anexo_boleto_ipe')?.setValue('')
    }
    // this.clearFieldsOCR();
  }

  async getPipesUrlArquivoBoleto(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        } (async () => {
          let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

          var fileUrlTemp = "";
          var startIndex = 0;

          if (urlResposta.indexOf("orgs") > 0) {
            startIndex = urlResposta.indexOf("orgs/");
          } else {
            startIndex = urlResposta.indexOf("uploads/");
          }

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.arquivosBoletoUrl.push(fileUrlTemp)
          console.log(this.arquivosBoletoUrl)
       })();
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosBoletoUrl = []
          }
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  getUserEmail() {
    this.email = this.userInfo.getUserEmail() ? this.userInfo.getUserEmail() : 'silvio.moreira';
    if (this.email && this.email.indexOf('nutrien') !== -1) {
      let name = this.email.substring(0, this.email.lastIndexOf("@"));
      this.formulario.controls['e_mail_do_requisitante']?.setValue(name);
    }
    if (this.email && this.email.indexOf('accenture') !== -1) {
      this.formulario.controls['e_mail_do_requisitante']?.setValue("silvio.moreira");
    }
  }


  changeTipoDeNota(event:any){
    let valor = event.target.value
    console.log(valor)

    switch(valor) {

    case "Aluguel":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Aluguel');
      this.changeCategoria('PO')
      this.changeSubcategoria('Aluguel')
      this.ajustaMoeda("Aluguel")
      this.camposOcr('Aluguel')
      break

    case "Ativo Fixo (AFE)":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Ativo Fixo (AFE)');
      this.changeCategoria('PO')
      this.changeSubcategoria('Ativo Fixo (AFE)')
      this.ajustaMoeda("Ativo Fixo (AFE)")
      this.camposOcr("Ativo Fixo (AFE)")
      break

    case "Beneficios":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Serviços');
      this.changeCategoria('PO')
      this.changeSubcategoria('Serviços')
      this.ajustaMoeda("Beneficios")
      this.camposOcr("Beneficios")
      break

      // Inserido como Água categoria e subcategoria qq um dos consumos seguindo orientação do Silvio

    case "Contas de consumo (Luz, água, telefone, internet, gás)":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Água');
      this.changeCategoria('PO')
      this.changeSubcategoria('Água')
      this.ajustaMoeda('Água')
      this.camposOcr("Contas de consumo (Luz, água, telefone, internet, gás)")
      break

    case "Correio":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Correios');
      this.changeCategoria('PO')
      this.changeSubcategoria('Correios')
      this.ajustaMoeda("Correio")
      this.camposOcr("Correio")
      break

    case "Escrituração - Não gera pagamento":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Serviços');
      this.changeCategoria('PO')
      this.changeSubcategoria('Serviços')
      this.ajustaMoeda("Escrituração - Não gera pagamento")
      this.camposOcr("Escrituração - Não gera pagamento")
      break

    case "Importação":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Serviços');
      this.changeCategoria('PO')
      this.changeSubcategoria('Serviços')
      this.ajustaMoeda("Importação")
      this.camposOcr("Importação")
      break

    case "Material (DANFE)":
      console.log("Entrei em material Danfe")
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Materiais');
      this.formulario.controls['tipo_de_opera_o']?.setValue('Outros');

      this.changeCategoria('PO')
      this.changeSubcategoria('Materiais')
      this.ajustaMoeda("Material (DANFE)")
      this.camposOcr("Material (DANFE)")
      break

    case "Material Compras e aquisição":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Materiais');
      this.formulario.controls['tipo_de_opera_o']?.setValue('Compras/Aquisição')
      this.changeCategoria('PO')
      this.changeSubcategoria('Materiais')
      this.ajustaMoeda("Material Compras e aquisição")
      this.camposOcr("Material Compras e aquisição")
      break

    case "Sem valor fiscal":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Serviços');
      this.changeCategoria('PO')
      this.changeSubcategoria('Serviços')
      this.ajustaMoeda("Sem valor fiscal")
      this.camposOcr("Sem valor fiscal")
      break

    case "Serviço":
      this.formulario.controls['categoria']?.setValue('PO');
      this.formulario.controls['subcategoria_po']?.setValue('Serviços');
      this.changeCategoria('PO')
      this.changeSubcategoria('Serviços')
      this.ajustaMoeda("Serviço")
      this.camposOcr("Serviço")
      // this.fornecedorSelecionado = ''
      break

    case "Frete/CTE":
      this.formularioLancamentos.controls['tipo_de_nota'].setValue('Frete/CTE');
    break

    case "Cartório":
      this.formulario.controls['categoria']?.setValue('NPO');
      this.formulario.controls['tipo_de_lan_amento']?.setValue('Cartório');
      this.changeCategoria('NPO')
      this.ajustaMoeda("Cartório")
      this.camposOcr("Cartório")
      break

    case "Devolução cliente":
      this.formulario.controls['categoria']?.setValue('NPO');
      this.formulario.controls['tipo_de_lan_amento']?.setValue('Devolução a clientes');
      this.changeCategoria('NPO')
      this.ajustaMoeda("Devolução cliente")
      this.camposOcr("Devolução cliente")
      break

    case "Guias/impostos/ taxas":
      this.formulario.controls['categoria']?.setValue('NPO');
      this.formulario.controls['tipo_de_lan_amento']?.setValue('Taxas, Guias e Imposto');
      this.changeCategoria('NPO')
      this.ajustaMoeda("Guias/impostos/ taxas")
      this.camposOcr("Guias/impostos/ taxas")
      break

    case "Incentivo - Doação":
      this.formulario.controls['categoria']?.setValue('NPO');
      this.formulario.controls['tipo_de_lan_amento']?.setValue('Doações');
      this.changeCategoria('NPO')
      this.ajustaMoeda("Incentivo - Doação")
      this.camposOcr("Incentivo - Doação")
      break

    case "Jurídico":
      this.formulario.controls['categoria']?.setValue('NPO');
      this.formulario.controls['tipo_de_lan_amento']?.setValue('Jurídico');
      this.changeCategoria('NPO')
      this.ajustaMoeda("Jurídico")
      this.camposOcr("Jurídico")
      break

    case "Nota de Crédito":
      this.formulario.controls['categoria']?.setValue('NPO');
      this.formulario.controls['tipo_de_lan_amento']?.setValue('Nota de Crédito');
      this.changeCategoria('NPO')
      this.ajustaMoeda("Nota de Crédito")
      this.camposOcr("Nota de Crédito")
      break

    case "Notas de Débito":
      this.formulario.controls['categoria']?.setValue('NPO');
      this.formulario.controls['tipo_de_lan_amento']?.setValue('Notas de Débito');
      this.changeCategoria('NPO')
      this.ajustaMoeda("Notas de Débito")
      this.camposOcr("Notas de Débito")
      break

    case "Remessa":
      this.formulario.controls['categoria']?.setValue('NPO');
      this.formulario.controls['tipo_de_lan_amento']?.setValue('Remessa');
      this.changeCategoria('NPO')
      this.ajustaMoeda("Remessa")
      this.camposOcr("Remessa")
      break

    default:
      this.formulario.controls['categoria']?.setValue('');
    }
  }


  ajustaMoeda(tipoDeNota: string){

    if(tipoDeNota ==  "Importação"){
      this.formulario.controls['moeda']?.setValue('USD');
    }
    else{
      this.formulario.controls['moeda']?.setValue('BRL');
    }
  }

  camposOcr(tipoDeNota: string){

    if(tipoDeNota == "Material Compras e aquisição"
    ||tipoDeNota == "Serviço"
    ||tipoDeNota == "Beneficios"
    ||tipoDeNota == "Escrituração - Não gera pagamento"
    ||tipoDeNota == "Importação"
    ||tipoDeNota == "Sem valor fiscal"
    ){
      this.formulario.controls['data_de_emiss_o']?.clearValidators();
      this.formulario.controls['data_de_emiss_o']?.updateValueAndValidity();

      this.formulario.controls['data_de_vencimento']?.clearValidators();
      this.formulario.controls['data_de_vencimento']?.updateValueAndValidity();

      this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
      this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

      this.formulario.controls['valor_da_fatura']?.clearValidators();
      this.formulario.controls['valor_da_fatura']?.updateValueAndValidity();

    }else{

      if(this.formulario.getRawValue().categoria == "PO"){
        console.log("entrei na validação PO")
        this.formulario.controls['data_de_emiss_o']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_emiss_o']?.updateValueAndValidity();

        this.formulario.controls['data_de_vencimento']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_vencimento']?.updateValueAndValidity();

        this.formulario.controls['numero_da_nota_fiscal']?.setValidators([Validators.required]);
        this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

        this.formulario.controls['valor_da_fatura']?.setValidators([Validators.required]);
        this.formulario.controls['valor_da_fatura']?.updateValueAndValidity();

      }

      if(this.formulario.getRawValue().categoria == "NPO"){
        console.log("entrei na validação NPO")
        this.formulario.controls['data_de_emiss_o']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_emiss_o']?.updateValueAndValidity();

        this.formulario.controls['data_de_vencimento']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_vencimento']?.updateValueAndValidity();

        // this.formulario.controls['numero_da_nota_fiscal']?.setValidators([Validators.required]);
        // this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

        this.formulario.controls['valor_da_fatura']?.setValidators([Validators.required]);
        this.formulario.controls['valor_da_fatura']?.updateValueAndValidity();

      }




    }

  }

  isAtivoFixo(event: any) {
    if(event.target.checked){
      this.formulario.controls['n_mero_afe']?.setValidators([Validators.required]);
      this.formulario.controls['n_mero_afe']?.updateValueAndValidity();
    }else{
      this.formulario.controls['n_mero_afe']?.setValue('');
      this.formulario.controls['n_mero_afe'].clearValidators();
      this.formulario.controls['n_mero_afe'].setErrors(null);
      this.formulario.controls['n_mero_afe'].updateValueAndValidity;
    }
  }













  getListaFornecedor() {
    this.spinner.show();
    let key = 'Emitente';
    this.pipefy.buscaTabelasMasterdata(key, '').then((res: any) => {

      let retorno = res.data.masterdata;

      if(retorno) {
        this.listaFornecedores.push({
          nome: 'NÃO CADASTRADO/ENCONTRADO',
          cardId: '666469115'
        })
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');

          this.listaFornecedores.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2],
            cardId: retorno[index].card_id
          })
        }
        this.listaFornecedoresOriginal = this.listaFornecedores;
        this.spinner.hide();
      } else {
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a Listagem Forncecedor. Por favor, tente novamente.',
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        this.spinner.hide();
      }

    },
    (error: any) => {
      this.spinner.hide();
      console.log(error)
    })
  }

  getListaTomador() {
    let key = 'Tomador';
    this.pipefy.buscaTabelasMasterdata(key, '').then((res: any) => {

      let retorno = res.data.masterdata;

      if(retorno) {
        this.listaTomadores.push({
          nome: 'NÃO CADASTRADO/ENCONTRADO',
          cardId: '666469142'
        })
        for (let index = 0; index < retorno.length; index++) {
          let label = retorno[index].label;
          let newLabel = label.split('_');

          this.listaTomadores.push({
            nome: newLabel[0],
            codErp: newLabel[1],
            cnpj: newLabel[2].replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
            cardId: retorno[index].card_id
          })
        }
        this.listaTomadoresOriginal = this.listaTomadores;
      } else {
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a Listagem Tomador. Por favor, tente novamente.',
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
  },

  (error: any) => {
      console.log(error)
  })
  }

  inserirNoArray(event:any){
    if(this.formulario.getRawValue().caracteristicas){
      this.caracteristicasChecked.push('"'+event+'"')
    } else {
      let eventEdit = `"${event}"`;
      this.caracteristicasChecked = this.caracteristicasChecked.filter(item => item !== eventEdit)
    }
    console.log(this.caracteristicasChecked)
  }

  cliqueSelectFornecedor() {
    this.listaFornecedores = this.listaFornecedoresOriginal;
    this.exibirListaFornecedores = !this.exibirListaFornecedores;
    this.formulario.get('inputSearchFornecedor')?.setValue('');
    this.exibeErroFornecedor = this.fornecedorSelecionado ? false : true;
    this.exibirListaTomadores = false;
  }

  cliqueSelectTomador() {
    this.listaTomadores = this.listaTomadoresOriginal;
    this.exibirListaTomadores = !this.exibirListaTomadores;
    this.formulario.get('inputSearchTomador')?.setValue('');
    this.exibeErroTomador = this.tomadorSelecionado ? false : true;
    this.exibirListaFornecedores = false;
  }

  clearFornecedor() {
    this.listaFornecedores = this.listaFornecedoresOriginal;
    this.formulario.get('inputSearchFornecedor')?.setValue('');
  }

  clearTomador() {
    this.listaTomadores = this.listaTomadoresOriginal;
    this.formulario.get('inputSearchTomador')?.setValue('');
  }

  searchFornecedor() {
    this.listaFornecedores = !this.listaFornecedores.length ? this.listaFornecedoresOriginal : this.listaFornecedores;
    let filtro = this.formulario.getRawValue().inputSearchFornecedor.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (filtro && filtro.length >= 11) {
      this.spinner.show();
      this.listaFornecedores = [];

      let key = 'Emitente';
      this.pipefy.buscaTabelasMasterdata(key, filtro).then((res: any) => {
        let retorno = res.data.masterdata;
        if (retorno.length >0) {
          for (let index = 0; index < retorno.length; index++) {
            let label = retorno[index].label;
            let newLabel = label.split('_');

            this.listaFornecedores.push({
              nome: newLabel[0],
              codErp: newLabel[1],
              cnpj: newLabel[2],
              cardId: retorno[index].card_id
            })
          }
        } else {
          this.listaFornecedores = [];
          this.listaFornecedores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469115'
          })
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.log(error.message)
        this.listaFornecedores = [];
          this.listaFornecedores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469115'
          })
      })
    } else {
      this.listaFornecedores = this.listaFornecedoresOriginal;
    }
  }

  searchFornecedor2(valor: any) {
    this.listaFornecedores = !this.listaFornecedores.length ? this.listaFornecedoresOriginal : this.listaFornecedores;
    let filtro = valor.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (filtro && filtro.length >= 11) {
      this.spinner.show();
      this.listaFornecedores = [];

      let key = 'Emitente';
      this.pipefy.buscaTabelasMasterdata(key, filtro).then((res: any) => {
        let retorno = res.data.masterdata;
        if (retorno.length >0) {
          for (let index = 0; index < retorno.length; index++) {
            let label = retorno[index].label;
            let newLabel = label.split('_');

            this.listaFornecedores.push({
              nome: newLabel[0],
              codErp: newLabel[1],
              cnpj: newLabel[2],
              cardId: retorno[index].card_id
            })
            this.fornecedorSelecionado = this.listaFornecedores[0];
          }
        } else {
          this.listaFornecedores = [];
          this.listaFornecedores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469115'
          })
        }
        // this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.log(error.message)
        this.listaFornecedores = [];
          this.listaFornecedores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469115'
          })
      })
    } else {
      this.listaFornecedores = this.listaFornecedoresOriginal;
    }
  }

  searchTomador() {
    this.listaTomadores = !this.listaTomadores.length ? this.listaTomadoresOriginal : this.listaTomadores;
    let filtro = this.formulario.getRawValue().inputSearchTomador.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (filtro && filtro.length >= 11) {
      this.spinner.show();
      this.listaTomadores = [];

      let key = 'Tomador';
      this.pipefy.buscaTabelasMasterdata(key, filtro).then((res: any) => {
        let retorno = res.data.masterdata;
        if (retorno.length >0) {
          for (let index = 0; index < retorno.length; index++) {
            let label = retorno[index].label;
            let newLabel = label.split('_');

            this.listaTomadores.push({
              nome: newLabel[0],
              codErp: newLabel[1],
              cnpj: newLabel[2],
              cardId: retorno[index].card_id
            })
          }
        } else {
          this.listaTomadores = [];
          this.listaTomadores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469142'
          })
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.log(error.message)
        this.listaTomadores = [];
          this.listaTomadores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469142'
          })
      })

    } else {
      this.listaTomadores = this.listaTomadoresOriginal;
    }
  }

  searchTomador2(valor: any) {
    this.listaTomadores = !this.listaTomadores.length ? this.listaTomadoresOriginal : this.listaTomadores;
    let filtro = valor.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (filtro && filtro.length >= 11) {
      this.spinner.show();
      this.listaTomadores = [];

      let key = 'Tomador';
      this.pipefy.buscaTabelasMasterdata(key, filtro).then((res: any) => {
        let retorno = res.data.masterdata;
        if (retorno.length >0) {
          for (let index = 0; index < retorno.length; index++) {
            let label = retorno[index].label;
            let newLabel = label.split('_');

            this.listaTomadores.push({
              nome: newLabel[0],
              codErp: newLabel[1],
              cnpj: newLabel[2],
              cardId: retorno[index].card_id
            })
          }
          this.tomadorSelecionado = this.listaTomadores[0];
        } else {
          this.listaTomadores = [];
          this.listaTomadores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469142'
          })
        }
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.log(error.message)
        this.listaTomadores = [];
          this.listaTomadores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469142'
          })
      })

    } else {
      this.listaTomadores = this.listaTomadoresOriginal;
    }
  }

  verificaContemNumeros(str: string) {
    return /^\d+$/.test(str);
  }

  selecionaFornecedor(item: any) {
    this.fornecedorSelecionado = item;
    this.listaFornecedores = this.listaFornecedoresOriginal;
    this.exibirListaFornecedores = false;
    this.exibeErroFornecedor = false;
  }

  selecionaTomador(item: any) {
    this.tomadorSelecionado = item;
    this.listaTomadores = this.listaTomadoresOriginal;
    this.exibirListaTomadores = false;
    this.exibeErroTomador = false;
  }

  changeCategoria(event:any) {
    console.log("Entrei")
    this.zerarTodosAnexos();
    switch (event) {
      case 'PO':

        this.categSelecionadaPO();
        this.changeTipoOperacao('');
        this.changeTipoLancamento('');
        break;
      case 'NPO':
        this.categSelecionadaNPO();
        // this.changeTipoOperacao('');
        // this.changeTipoLancamento('');
        break
      default:
        this.categSelecionadaVazio();
        this.changeTipoOperacao('');
        this.changeTipoLancamento('');
        break;
    }
  }


  onTipoChange() {
    let quantidadeDeItens = this.formulario.getRawValue().qtde_pedidos
    this.clearValidatorsDetalhes()
    this.limpaValoresProdutos(quantidadeDeItens);

    switch (quantidadeDeItens) {
      // @ts-ignore
      case '1':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        break;

      case '2':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        break;

      case '3':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        break;

      case '4':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        break;

      case '5':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        break;

      case '6':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        break;

      case '7':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        break;

      case '8':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        break;

      case '9':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();
        break;

      case '10':
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();

        this.formulario.controls['numero_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();
        break;

      case 'Mais que 10':
        this.formulario.controls['lista_pedidos']?.setValidators([Validators.required])
        this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
        break;

      default:
        this.formulario.controls['numero_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['lista_pedidos']?.setValidators([Validators.required])
        this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
        break;
    }
  }

  clearValidatorsDetalhes(){
    this.formulario.controls['numero_pedido_1']?.clearValidators();
    this.formulario.controls['numero_pedido_1']?.markAsUntouched();
    this.formulario.controls['numero_pedido_1']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_1']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_1']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_2']?.clearValidators();
    this.formulario.controls['numero_pedido_2']?.markAsUntouched();
    this.formulario.controls['numero_pedido_2']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_2']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_2']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_3']?.clearValidators();
    this.formulario.controls['numero_pedido_3']?.markAsUntouched();
    this.formulario.controls['numero_pedido_3']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_3']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_3']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_4']?.clearValidators();
    this.formulario.controls['numero_pedido_4']?.markAsUntouched();
    this.formulario.controls['numero_pedido_4']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_4']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_4']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_5']?.clearValidators();
    this.formulario.controls['numero_pedido_5']?.markAsUntouched();
    this.formulario.controls['numero_pedido_5']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_5']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_5']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_6']?.clearValidators();
    this.formulario.controls['numero_pedido_6']?.markAsUntouched();
    this.formulario.controls['numero_pedido_6']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_6']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_6']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_7']?.clearValidators();
    this.formulario.controls['numero_pedido_7']?.markAsUntouched();
    this.formulario.controls['numero_pedido_7']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_7']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_7']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_8']?.clearValidators();
    this.formulario.controls['numero_pedido_8']?.markAsUntouched();
    this.formulario.controls['numero_pedido_8']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_8']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_8']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_9']?.clearValidators();
    this.formulario.controls['numero_pedido_9']?.markAsUntouched();
    this.formulario.controls['numero_pedido_9']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_9']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_9']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();

    this.formulario.controls['numero_pedido_10']?.clearValidators();
    this.formulario.controls['numero_pedido_10']?.markAsUntouched();
    this.formulario.controls['numero_pedido_10']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_10']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_10']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();

    this.formulario.controls['lista_pedidos']?.clearValidators();
    this.formulario.controls['lista_pedidos']?.markAsUntouched();
    this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
  }

  limpaValoresProdutos(quantidadeDeItens: any) {
    switch (quantidadeDeItens) {
      // @ts-ignore
      case '1':
        this.formulario.controls['numero_pedido_2']?.setValue('');
        this.formulario.controls['numero_pedido_2'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '2':
        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '3':
        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '4':
        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '5':
        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '6':
        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '7':
        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '8':
        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '9':
        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '10':
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case 'Mais que 10':
        this.formulario.controls['numero_pedido_1']?.setValue('');
        this.formulario.controls['numero_pedido_1'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_1']?.setValue('');
        this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();

        this.formulario.controls['numero_pedido_2']?.setValue('');
        this.formulario.controls['numero_pedido_2'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        break;

      default:
        this.formulario.controls['numero_pedido_1']?.setValue('');
        this.formulario.controls['numero_pedido_1'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_1']?.setValue('');
        this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();

        this.formulario.controls['numero_pedido_2']?.setValue('');
        this.formulario.controls['numero_pedido_2'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_pedido_3']?.setValue('');
        this.formulario.controls['numero_pedido_3'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_pedido_4']?.setValue('');
        this.formulario.controls['numero_pedido_4'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_pedido_5']?.setValue('');
        this.formulario.controls['numero_pedido_5'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_pedido_6']?.setValue('');
        this.formulario.controls['numero_pedido_6'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_pedido_7']?.setValue('');
        this.formulario.controls['numero_pedido_7'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_pedido_8']?.setValue('');
        this.formulario.controls['numero_pedido_8'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_pedido_9']?.setValue('');
        this.formulario.controls['numero_pedido_9'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_pedido_10']?.setValue('');
        this.formulario.controls['numero_pedido_10'].markAsUntouched();
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;
    }
  }

  categSelecionadaVazio() {
    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.markAsUntouched();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['numero_da_nota_fiscal']?.setValue('');
    this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
    this.formulario.controls['numero_da_nota_fiscal']?.markAsUntouched();
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.zerarTodosAnexos();

    this.zerarTodosCamposQtdePedidos();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.markAsUntouched();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    // this.formulario.controls['favorecido']?.setValue('');
    // this.formulario.controls['favorecido']?.clearValidators();
    // this.formulario.controls['favorecido']?.markAsUntouched();
    // this.formulario.controls['favorecido']?.updateValueAndValidity();

    // this.formulario.controls['valor_do_fi']?.setValue('');
    // this.formulario.controls['valor_do_fi']?.clearValidators();
    // this.formulario.controls['valor_do_fi']?.markAsUntouched();
    // this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();

    this.formularioLancamentos.controls['tipo_de_nota'].setValue('');
    this.formularioLancamentos.controls['qual_a_quantidade_de_cte'].setValue('');
  }

  categSelecionadaNPO() {
    this.formulario.controls['tipo_de_lan_amento']?.setValidators([Validators.required]);
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['anexo_npo_modelo_excel']?.setValidators([Validators.required]);
    this.formulario.controls['anexo_npo_modelo_excel']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValidators([Validators.required]);
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    // this.formulario.controls['favorecido']?.setValidators([Validators.required]);
    // this.formulario.controls['favorecido']?.updateValueAndValidity();

    // this.formulario.controls['valor_do_fi']?.setValidators([Validators.required]);
    // this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.markAsUntouched();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formularioLancamentos.controls['tipo_de_nota'].setValue('');
    this.formularioLancamentos.controls['qual_a_quantidade_de_cte'].setValue('');

    this.formulario.controls['numero_da_nota_fiscal']?.setValue('');
    this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
    this.formulario.controls['numero_da_nota_fiscal']?.markAsUntouched();
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.zerarTodosCamposQtdePedidos();
  }

  categSelecionadaPO() {
    this.formulario.controls['subcategoria_po']?.setValidators([Validators.required]);
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['qtde_pedidos']?.setValidators([Validators.required]);
    this.formulario.controls['qtde_pedidos']?.updateValueAndValidity();

    this.formulario.controls['numero_da_nota_fiscal']?.setValidators([Validators.required]);
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.markAsUntouched();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    // this.formulario.controls['favorecido']?.setValue('');
    // this.formulario.controls['favorecido']?.clearValidators();
    // this.formulario.controls['favorecido']?.markAsUntouched();
    // this.formulario.controls['favorecido']?.updateValueAndValidity();

    // this.formulario.controls['valor_do_fi']?.setValue('');
    // this.formulario.controls['valor_do_fi']?.clearValidators();
    // this.formulario.controls['valor_do_fi']?.markAsUntouched();
    // this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();

    this.formularioLancamentos.controls['tipo_de_nota'].setValue('');
    this.formularioLancamentos.controls['qual_a_quantidade_de_cte'].setValue('');
  }

  changeSubcategoria(event: any) {
    console.log("subcategoria: ", event)
    this.zerarTodosCamposSubcategoria();
    this.zerarTodosAnexos();
    switch (event) {
      case 'Materiais':

        console.log("entrei em materiais")
        this.formulario.controls['tipo_de_opera_o']?.setValidators([Validators.required]);
        this.formulario.controls['tipo_de_opera_o']?.updateValueAndValidity();

        this.formulario.controls['qtde_pedidos']?.setValidators([Validators.required]);
        this.formulario.controls['qtde_pedidos']?.updateValueAndValidity();

        this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.updateValueAndValidity();

        this.formularioLancamentos.controls['tipo_de_nota'].setValue('');
        this.formularioLancamentos.controls['qual_a_quantidade_de_cte'].setValue('');

        if(this.formulario.getRawValue().tipo_de_nota=="Material (DANFE)"){
          this.formulario.controls['tipo_de_opera_o']?.setValue("Outros")
        }

        if(this.formulario.getRawValue().tipo_de_nota=="Material Compras e aquisição"){
          this.formulario.controls['tipo_de_opera_o']?.setValue("Compras/Aquisição")
        }

        break;
      case 'Serviços':

        console.log("entrei em serviço")

        this.formulario.controls['boleto_para_pagamento']?.setValidators([Validators.required]);
        this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

        // this.formulario.controls['folha_servicos']?.setValidators([Validators.required]);
        // this.formulario.controls['folha_servicos']?.updateValueAndValidity();

        this.formulario.controls['qtde_pedidos']?.setValidators([Validators.required]);
        this.formulario.controls['qtde_pedidos']?.updateValueAndValidity();

        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.updateValueAndValidity();

        this.formularioLancamentos.controls['tipo_de_nota'].setValue('');
        this.formularioLancamentos.controls['qual_a_quantidade_de_cte'].setValue('');
        break
      case 'Frete':
        this.formularioLancamentos.controls['tipo_de_nota'].setValue('Frete/CTE');
        // this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValidators([Validators.required]);
        // this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.updateValueAndValidity();
        break
      case 'Benefícios':
        console.log("entrei em beneficios")
        this.formularioLancamentos.controls['tipo_de_nota']?.setValue('Benefícios');
        break;
      case 'Boleto':
      case 'Nota de Débito':
      case 'Telefonia':
      case 'Água':
      case 'Energia':
      case 'Fatura':
      case 'Aluguel':
      case 'Correios':
        console.log("entrei em outros")
        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.updateValueAndValidity();
        this.formulario.controls['qtde_pedidos']?.setValidators([Validators.required]);
        this.formulario.controls['qtde_pedidos']?.updateValueAndValidity();

        this.formularioLancamentos.controls['tipo_de_nota'].setValue('');
        this.formularioLancamentos.controls['qual_a_quantidade_de_cte'].setValue('');
        break
      default:
        this.formularioLancamentos.controls['tipo_de_nota'].setValue('');
        this.formularioLancamentos.controls['qual_a_quantidade_de_cte'].setValue('');
        this.formularioLancamentos.controls['tipo_de_nota']?.setValue('');
        break;
    }
  }

  changeTipoOperacao(event: any) {
    let elem = event.target ? event.target.value : event;
    switch (elem) {
      case 'Matéria Prima':
        this.formulario.controls['migo']?.setValidators([Validators.required]);
        this.formulario.controls['migo']?.updateValueAndValidity();
        break;
      default:
        this.formulario.controls['migo']?.setValue('');
        this.formulario.controls['migo']?.clearValidators();
        this.formulario.controls['migo']?.markAsUntouched();
        this.formulario.controls['migo']?.updateValueAndValidity();
        break;
    }
  }

  changeTipoLancamento(event: any) {
    let elem = event.target ? event.target.value : event;
    switch (elem) {
      // case 'Depósito Judicial':
      //   this.formulario.controls['n_mero_do_processo']?.setValidators([Validators.required]);
      //   this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();
      //   break;
      case 'Benefícios':
        this.formularioLancamentos.controls['tipo_de_nota']?.setValue('Benefícios');
        break;
      default:
        // this.formulario.controls['n_mero_do_processo']?.setValue('');
        // this.formulario.controls['n_mero_do_processo']?.clearValidators();
        // this.formulario.controls['n_mero_do_processo']?.markAsUntouched();
        // this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();
        this.formularioLancamentos.controls['tipo_de_nota']?.setValue('');

        break;
    }
  }

  zerarTodosAnexos() {
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver']?.setValue('');
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].setErrors(null);
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].markAsUntouched();
    this.formulario.controls['anexo_nota_fiscal_documento_boleto_se_houver'].updateValueAndValidity;

    this.formulario.controls['anexo_xml'].clearValidators();
    this.formulario.controls['anexo_xml']?.setValue('');
    this.formulario.controls['anexo_xml'].setErrors(null);
    this.formulario.controls['anexo_xml'].markAsUntouched();
    this.formulario.controls['anexo_xml'].updateValueAndValidity;

    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].clearValidators();
    this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValue('');
    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].setErrors(null);
    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].markAsUntouched();
    this.formulario.controls['anexo_nota_fiscal_ou_boleto'].updateValueAndValidity;

    this.formulario.controls['anexo_npo_modelo_excel'].clearValidators();
    this.formulario.controls['anexo_npo_modelo_excel']?.setValue('');
    this.formulario.controls['anexo_npo_modelo_excel'].setErrors(null);
    this.formulario.controls['anexo_npo_modelo_excel'].markAsUntouched();
    this.formulario.controls['anexo_npo_modelo_excel'].updateValueAndValidity;

    this.formulario.controls['anexo_boleto_ipe'].clearValidators();
    this.formulario.controls['anexo_boleto_ipe']?.setValue('');
    this.formulario.controls['anexo_boleto_ipe'].setErrors(null);
    this.formulario.controls['anexo_boleto_ipe'].markAsUntouched();
    this.formulario.controls['anexo_boleto_ipe'].updateValueAndValidity;

    this.arquivosXml = [];
    this.arquivosXmlUrl = [];

    this.arquivosNf = [];
    this.arquivosNfUrl = [];

    this.arquivosNfBoleto = [];
    this.arquivosNfBoletoUrl = [];

    this.arquivosNpo = [];
    this.arquivosNpoUrl = [];

    this.arquivosBoleto = [];
    this.arquivosBoletoUrl = [];

  }

  zerarTodosCamposSubcategoria() {

    this.zerarTodosCamposQtdePedidos();

    this.formulario.controls['tipo_de_opera_o'].setValue('');
    this.formulario.controls['tipo_de_opera_o'].clearValidators();
    this.formulario.controls['tipo_de_opera_o'].setErrors(null);
    this.formulario.controls['tipo_de_opera_o'].markAsUntouched();
    this.formulario.controls['tipo_de_opera_o'].updateValueAndValidity();

    this.formulario.controls['marque_todas_as_op_es_que_se_aplicam'].setValue('');

    this.formulario.controls['boleto_para_pagamento'].setValue('');
    this.formulario.controls['boleto_para_pagamento'].clearValidators();
    this.formulario.controls['boleto_para_pagamento'].setErrors(null);
    this.formulario.controls['boleto_para_pagamento'].markAsUntouched();
    this.formulario.controls['boleto_para_pagamento'].updateValueAndValidity();

    this.formulario.controls['folha_servicos'].setValue('');
    this.formulario.controls['folha_servicos'].clearValidators();
    this.formulario.controls['folha_servicos'].setErrors(null);
    this.formulario.controls['folha_servicos'].markAsUntouched();
    this.formulario.controls['folha_servicos'].updateValueAndValidity();
  }

  zerarTodosCamposQtdePedidos() {
    this.formulario.controls['qtde_pedidos'].setValue('');
    this.formulario.controls['qtde_pedidos'].clearValidators();
    this.formulario.controls['qtde_pedidos'].setErrors(null);
    this.formulario.controls['qtde_pedidos'].markAsUntouched();
    this.formulario.controls['qtde_pedidos'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_1'].setValue('');
    this.formulario.controls['numero_pedido_1'].clearValidators();
    this.formulario.controls['numero_pedido_1'].setErrors(null);
    this.formulario.controls['numero_pedido_1'].markAsUntouched();
    this.formulario.controls['numero_pedido_1'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_1'].setValue('');
    this.formulario.controls['numero_linha_pedido_1'].clearValidators();
    this.formulario.controls['numero_linha_pedido_1'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_1'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_2'].setValue('');
    this.formulario.controls['numero_pedido_2'].clearValidators();
    this.formulario.controls['numero_pedido_2'].setErrors(null);
    this.formulario.controls['numero_pedido_2'].markAsUntouched();
    this.formulario.controls['numero_pedido_2'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_2'].setValue('');
    this.formulario.controls['numero_linha_pedido_2'].clearValidators();
    this.formulario.controls['numero_linha_pedido_2'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_2'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_3'].setValue('');
    this.formulario.controls['numero_pedido_3'].clearValidators();
    this.formulario.controls['numero_pedido_3'].setErrors(null);
    this.formulario.controls['numero_pedido_3'].markAsUntouched();
    this.formulario.controls['numero_pedido_3'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_3'].setValue('');
    this.formulario.controls['numero_linha_pedido_3'].clearValidators();
    this.formulario.controls['numero_linha_pedido_3'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_3'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_4'].setValue('');
    this.formulario.controls['numero_pedido_4'].clearValidators();
    this.formulario.controls['numero_pedido_4'].setErrors(null);
    this.formulario.controls['numero_pedido_4'].markAsUntouched();
    this.formulario.controls['numero_pedido_4'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_4'].setValue('');
    this.formulario.controls['numero_linha_pedido_4'].clearValidators();
    this.formulario.controls['numero_linha_pedido_4'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_4'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_5'].setValue('');
    this.formulario.controls['numero_pedido_5'].clearValidators();
    this.formulario.controls['numero_pedido_5'].setErrors(null);
    this.formulario.controls['numero_pedido_5'].markAsUntouched();
    this.formulario.controls['numero_pedido_5'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_5'].setValue('');
    this.formulario.controls['numero_linha_pedido_5'].clearValidators();
    this.formulario.controls['numero_linha_pedido_5'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_5'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_6'].setValue('');
    this.formulario.controls['numero_pedido_6'].clearValidators();
    this.formulario.controls['numero_pedido_6'].setErrors(null);
    this.formulario.controls['numero_pedido_6'].markAsUntouched();
    this.formulario.controls['numero_pedido_6'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_6'].setValue('');
    this.formulario.controls['numero_linha_pedido_6'].clearValidators();
    this.formulario.controls['numero_linha_pedido_6'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_6'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_7'].setValue('');
    this.formulario.controls['numero_pedido_7'].clearValidators();
    this.formulario.controls['numero_pedido_7'].setErrors(null);
    this.formulario.controls['numero_pedido_7'].markAsUntouched();
    this.formulario.controls['numero_pedido_7'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_7'].setValue('');
    this.formulario.controls['numero_linha_pedido_7'].clearValidators();
    this.formulario.controls['numero_linha_pedido_7'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_7'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_8'].setValue('');
    this.formulario.controls['numero_pedido_8'].clearValidators();
    this.formulario.controls['numero_pedido_8'].setErrors(null);
    this.formulario.controls['numero_pedido_8'].markAsUntouched();
    this.formulario.controls['numero_pedido_8'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_8'].setValue('');
    this.formulario.controls['numero_linha_pedido_8'].clearValidators();
    this.formulario.controls['numero_linha_pedido_8'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_8'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_9'].setValue('');
    this.formulario.controls['numero_pedido_9'].clearValidators();
    this.formulario.controls['numero_pedido_9'].setErrors(null);
    this.formulario.controls['numero_pedido_9'].markAsUntouched();
    this.formulario.controls['numero_pedido_9'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_9'].setValue('');
    this.formulario.controls['numero_linha_pedido_9'].clearValidators();
    this.formulario.controls['numero_linha_pedido_9'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_9'].updateValueAndValidity();

    this.formulario.controls['numero_pedido_10'].setValue('');
    this.formulario.controls['numero_pedido_10'].clearValidators();
    this.formulario.controls['numero_pedido_10'].setErrors(null);
    this.formulario.controls['numero_pedido_10'].markAsUntouched();
    this.formulario.controls['numero_pedido_10'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_10'].setValue('');
    this.formulario.controls['numero_linha_pedido_10'].clearValidators();
    this.formulario.controls['numero_linha_pedido_10'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_10'].updateValueAndValidity();

    this.formulario.controls['lista_pedidos'].setValue('');
    this.formulario.controls['lista_pedidos'].clearValidators();
    this.formulario.controls['lista_pedidos'].setErrors(null);
    this.formulario.controls['lista_pedidos'].markAsUntouched();
    this.formulario.controls['lista_pedidos'].updateValueAndValidity();
  }

  onBoletoPagamentoChange(event: any) {
    if (event.target.value === "Sim") {
      this.formulario.get('forma_de_pagamento')?.setValue('Boleto');
    } else {
      this.formulario.get('forma_de_pagamento')?.setValue('');
    }
  }

  validaCampoEmail() {
    let input = this.formulario.getRawValue().e_mail_do_requisitante
    var regex = new RegExp("^[a-zA-Z0-9-Z+\\.\\_\\-\b]+$");
    if(!regex.test(input))
      input = (input.substring(0, (input.length-1)))
    this.formulario.controls['e_mail_do_requisitante']?.setValue(input);
  }

  formataValorDecimal( input: any ) {
    input = input.replace( /[^0-9]/g, '' ); // remove all the characters except the numeric values
    if( input.length == 0 ) input = "0.00"; // set the default value
    else if( input.length == 1 ) input = "0.0" + input;
    else if( input.length == 2 ) input = "0." + input;
    else input = input.substring( 0, input.length - 2 ) + '.' + input.substring( input.length - 2, input.length );
    input = new Number( input ); // set the precision
    input = input.toFixed( 2 );    // only works with the "."
    input = input.replace( /\./g, ',' ); // change the splitter to ","
    let x = input.split( ',' ); // format the amount
    let valor = x[0];
    let decimal = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while( rgx.test( valor ) ) {
      valor = valor.replace( rgx, '$1' + '.' + '$2' );
    }
    this.valorFaturaAlterado = valor + decimal;

  }

  formataValorTotalNfDecimal( input: any ) {
    input = input.replace( /[^0-9]/g, '' ); // remove all the characters except the numeric values
    if( input.length == 0 ) input = "0.00"; // set the default value
    else if( input.length == 1 ) input = "0.0" + input;
    else if( input.length == 2 ) input = "0." + input;
    else input = input.substring( 0, input.length - 2 ) + '.' + input.substring( input.length - 2, input.length );
    input = new Number( input ); // set the precision
    input = input.toFixed( 2 );    // only works with the "."
    input = input.replace( /\./g, ',' ); // change the splitter to ","
    let x = input.split( ',' ); // format the amount
    let valor = x[0];
    let decimal = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while( rgx.test( valor ) ) {
      valor = valor.replace( rgx, '$1' + '.' + '$2' );
    }
    this.valorTotalNfAlterado = valor + decimal
  }

  getDataAtual() {
    let newDate = new Date(),
        month = '' + (newDate.getMonth() + 1),
        day = '' + newDate.getDate(),
        year = newDate.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    this.dataAtual = [year, month, day].join('-');
  }

  exibeConfirmaValor() {
    Swal.fire({
      title: 'Atenção!',
      text: 'Confirmar se o valor corresponde exatamente ao valor do documento em PDF anexo.',
      // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#88D600',
    })
  }

  changePossuiBoleto(event: any) {
    this.possuiBoletoChecked = event;
    if (this.possuiBoletoChecked) {
      this.formulario.controls['anexo_boleto_ipe']?.setValidators([Validators.required]);
      this.formulario.controls['anexo_boleto_ipe']?.updateValueAndValidity();
    } else {
      this.formulario.controls['anexo_boleto_ipe']?.setValue('');
      this.formulario.controls['anexo_boleto_ipe'].clearValidators();
      this.formulario.controls['anexo_boleto_ipe'].setErrors(null);
      this.formulario.controls['anexo_boleto_ipe'].markAsUntouched();
      this.formulario.controls['anexo_boleto_ipe'].updateValueAndValidity;

      this.arquivosBoleto = [];
      this.arquivosBoletoUrl = [];
    }
  }

  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched();

    this.caracteristicasChecked = [];

    this.zerarTodosCamposSubcategoria();

    this.zerarTodosAnexos();

    this.fornecedorSelecionado = {nome: '', codErp: '', cnpj: '', cardId: ''};
    this.tomadorSelecionado = {nome: '', codErp: '', cnpj: '', cardId: ''};

    this.formulario.controls['tipo_de_lan_amento'].clearValidators();
    this.formulario.controls['tipo_de_lan_amento'].setErrors(null);
    this.formulario.controls['tipo_de_lan_amento'].markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento'].updateValueAndValidity;


    // this.formulario.controls['favorecido'].clearValidators();
    // this.formulario.controls['favorecido'].setErrors(null);
    // this.formulario.controls['favorecido'].markAsUntouched();
    // this.formulario.controls['favorecido'].updateValueAndValidity;


    // this.formulario.controls['valor_do_fi'].clearValidators();
    // this.formulario.controls['valor_do_fi'].setErrors(null);
    // this.formulario.controls['valor_do_fi'].markAsUntouched();
    // this.formulario.controls['valor_do_fi'].updateValueAndValidity;


    this.formulario.controls['subcategoria_po'].clearValidators();
    this.formulario.controls['subcategoria_po'].setErrors(null);
    this.formulario.controls['subcategoria_po'].markAsUntouched();
    this.formulario.controls['subcategoria_po'].updateValueAndValidity;

    this.formulario.controls['numero_da_nota_fiscal']?.setValue('');
    this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
    this.formulario.controls['numero_da_nota_fiscal']?.markAsUntouched();
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.possuiBoletoChecked = false;

    this.formulario.controls['n_mero_afe']?.setValue('');
    this.formulario.controls['n_mero_afe'].clearValidators();
    this.formulario.controls['n_mero_afe'].setErrors(null);
    this.formulario.controls['n_mero_afe'].updateValueAndValidity;


    // this.formulario.controls['n_mero_do_processo'].clearValidators();
    // this.formulario.controls['n_mero_do_processo'].setErrors(null);
    // this.formulario.controls['n_mero_do_processo'].markAsUntouched();
    // this.formulario.controls['n_mero_do_processo'].updateValueAndValidity;

    this.resNotaJsonMost = "";
    this.resBoletoJsonMost = "";

    this.getUserEmail();
  }

  abrirPipePublico() {
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }






  // ************************************* Lançamento de Notas *******************************************************************



  delayLancamentos(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }


    saveArquivoRateio(event: any): void {
      var files = event.target.files;

      for (var i = 0; i < files.length; i++) {
        this.arquivosDeRateio.push(files[i])
      }
    }

    removeFileArquivoRateio(file: File) {
      const index: number = this.arquivosDeRateio.indexOf(file);
      this.arquivosDeRateio.splice(index, 1)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Arquivo Removido com Sucesso.',
        showConfirmButton: false,
        timer: 1500
      })
      if(this.arquivosDeRateio.length == 0){
        this.formularioLancamentos.controls['arquivo_de_rateio'].markAsTouched()
        this.formularioLancamentos.get('arquivo_de_rateio')?.setValue('')
      }
    }

    async getPipesUrlArquivoRateio(files: any) {
      this.erro = false
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = async () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")

          fileContent = fileContent.substring(fileContentStart + 1)
          fileContent = fileContent.toString()

          var binaryString = window.atob(fileContent);
          var byteArray = new Uint8Array(binaryString.length);
          for (var i=0; i<binaryString.length; i++){
          byteArray[i] += binaryString.charCodeAt(i);
         }/*from w  ww .  j a v a 2  s  .c  o  m*/

         let urlResposta:any = await this.pipefy.getFileUrl3(byteArray, fileName)


            var fileUrlTemp = "";
            var startIndex = 0;

            if (urlResposta.indexOf("orgs") > 0) {
              startIndex = urlResposta.indexOf("orgs/");
            } else {
              startIndex = urlResposta.indexOf("uploads/");
            }

            var endIndex = urlResposta.indexOf("?");
            fileUrlTemp = urlResposta.substring(startIndex, endIndex);

            this.arquivosDeRateioUrl.push(fileUrlTemp)
          },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            // console.log(error.status)
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.arquivosDeRateioUrl = []
            }
        }
      }
      return new Promise<void>((resolve) => {resolve();});
    }

    saveAnexoNota(event: any): void {
      var files = event.target.files;

      for (var i = 0; i < files.length; i++) {
        this.notas.push(files[i])
        console.log(this.notas)
      }
    }

    removeFileNota(file: File) {
      const index: number = this.notas.indexOf(file);
      this.notas.splice(index, 1)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Arquivo Removido com Sucesso.',
        showConfirmButton: false,
        timer: 1500
      })
      if(this.notas.length == 0){
        this.formularioLancamentos.controls['anexo_nota_ou_boleto'].markAsTouched()
        this.formularioLancamentos.get('anexo_nota_ou_boleto')?.setValue('')

      }
    }

    async getPipesUrlNota(files: any) {
      this.erro = false
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = async () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")

          fileContent = fileContent.substring(fileContentStart + 1)
          fileContent = fileContent.toString()

          var binaryString = window.atob(fileContent);
          var byteArray = new Uint8Array(binaryString.length);
          for (var i=0; i<binaryString.length; i++){
          byteArray[i] += binaryString.charCodeAt(i);
         }/*from w  ww .  j a v a 2  s  .c  o  m*/

          let urlResposta:any = await this.pipefy.getFileUrl3(byteArray, fileName)
            // let url = res.data.attachments_url_pipefy[0]
            // this.arquivosNfBoletoUrl.push(url)
            console.log(urlResposta)
            // urlResposta = urlResposta.body.data.createPresignedUrl.url

            var fileUrlTemp = "";
            var startIndex = 0;

            if (urlResposta.indexOf("orgs") > 0) {
              startIndex = urlResposta.indexOf("orgs/");
            } else {
              startIndex = urlResposta.indexOf("uploads/");
            }

            var endIndex = urlResposta.indexOf("?");
            fileUrlTemp = urlResposta.substring(startIndex, endIndex);

            this.notasUrl.push(fileUrlTemp)
        console.log(this.notas)

          },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            // console.log(error.status)
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.notasUrl = []
            }


        }
      }
      return new Promise<void>((resolve) => {resolve();});
    }

    saveAnexoBoleto(event: any): void {
      var files = event.target.files;

      for (var i = 0; i < files.length; i++) {
        this.boletos.push(files[i])
        console.log(this.boletos)

      }
    }

    removeFileBoleto(file: File) {
      const index: number = this.boletos.indexOf(file);
      this.boletos.splice(index, 1)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Arquivo Removido com Sucesso.',
        showConfirmButton: false,
        timer: 1500
      })
      if(this.boletos.length == 0){
        this.formularioLancamentos.controls['anexo_boleto'].markAsTouched()
        this.formularioLancamentos.get('anexo_boleto')?.setValue('')
      }
    }

    async getPipesUrlBoleto(files: any) {
      this.erro = false
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = async () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")

          fileContent = fileContent.substring(fileContentStart + 1)
          fileContent = fileContent.toString()

          var binaryString = window.atob(fileContent);
          var byteArray = new Uint8Array(binaryString.length);
          for (var i=0; i<binaryString.length; i++){
          byteArray[i] += binaryString.charCodeAt(i);
         }/*from w  ww .  j a v a 2  s  .c  o  m*/

         let urlResposta:any = await this.pipefy.getFileUrl3(byteArray, fileName)


            var fileUrlTemp = "";
            var startIndex = 0;

            if (urlResposta.indexOf("orgs") > 0) {
              startIndex = urlResposta.indexOf("orgs/");
            } else {
              startIndex = urlResposta.indexOf("uploads/");
            }

            var endIndex = urlResposta.indexOf("?");
            fileUrlTemp = urlResposta.substring(startIndex, endIndex);

            this.boletosUrl.push(fileUrlTemp)
        console.log(this.boletos)

          },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            // console.log(error.status)
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.boletosUrl = []
            }
        }
      }
      return new Promise<void>((resolve) => {resolve();});
    }

    saveAnexoXML(event: any): void {
      var files = event.target.files;

      for (var i = 0; i < files.length; i++) {
        this.xml.push(files[i])
        console.log(this.xml)

      }
    }

    removeFileXML(file: File) {
      const index: number = this.xml.indexOf(file);
      this.xml.splice(index, 1)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Arquivo Removido com Sucesso.',
        showConfirmButton: false,
        timer: 1500
      })
      if(this.xml.length == 0){
        this.formularioLancamentos.controls['anexo_xml'].markAsTouched()
        this.formularioLancamentos.get('anexo_xml')?.setValue('')
      }
    }

    async getPipesUrlXML(files: any) {
      this.erro = false
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = async () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")

          fileContent = fileContent.substring(fileContentStart + 1)
          fileContent = fileContent.toString()

          var binaryString = window.atob(fileContent);
          var byteArray = new Uint8Array(binaryString.length);
          for (var i=0; i<binaryString.length; i++){
          byteArray[i] += binaryString.charCodeAt(i);
         }/*from w  ww .  j a v a 2  s  .c  o  m*/

         let urlResposta:any = await this.pipefy.getFileUrl3(byteArray, fileName)


            var fileUrlTemp = "";
            var startIndex = 0;

            if (urlResposta.indexOf("orgs") > 0) {
              startIndex = urlResposta.indexOf("orgs/");
            } else {
              startIndex = urlResposta.indexOf("uploads/");
            }

            var endIndex = urlResposta.indexOf("?");
            fileUrlTemp = urlResposta.substring(startIndex, endIndex);

            this.xmlUrl.push(fileUrlTemp)
        console.log(this.xml)

          },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            // console.log(error.status)
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.xmlUrl = []
            }
        }
      }
      return new Promise<void>((resolve) => {resolve();});
    }












    saveAnexoDocumentacaoSuporte(event: any): void {
      var files = event.target.files;

      for (var i = 0; i < files.length; i++) {
        this.documentacaoSuporte.push(files[i])
        console.log(this.documentacaoSuporte)

      }
    }

    removeFilDocumentacaoSuporte(file: File) {
      const index: number = this.documentacaoSuporte.indexOf(file);
      this.documentacaoSuporte.splice(index, 1)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Arquivo Removido com Sucesso.',
        showConfirmButton: false,
        timer: 1500
      })
      if(this.documentacaoSuporte.length == 0){
        this.formularioLancamentos.controls['anexo_documentacao_suporte'].markAsTouched()
        this.formularioLancamentos.get('anexo_documentacao_suporte')?.setValue('')
      }
    }

    async getPipesUrlDocumentacaoSuporte(files: any) {
      this.erro = false
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = async () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")

          fileContent = fileContent.substring(fileContentStart + 1)
          fileContent = fileContent.toString()

          var binaryString = window.atob(fileContent);
          var byteArray = new Uint8Array(binaryString.length);
          for (var i=0; i<binaryString.length; i++){
          byteArray[i] += binaryString.charCodeAt(i);
         }/*from w  ww .  j a v a 2  s  .c  o  m*/

         let urlResposta:any = await this.pipefy.getFileUrl3(byteArray, fileName)


            var fileUrlTemp = "";
            var startIndex = 0;

            if (urlResposta.indexOf("orgs") > 0) {
              startIndex = urlResposta.indexOf("orgs/");
            } else {
              startIndex = urlResposta.indexOf("uploads/");
            }

            var endIndex = urlResposta.indexOf("?");
            fileUrlTemp = urlResposta.substring(startIndex, endIndex);

            this.documentacaoSuporteUrl.push(fileUrlTemp)
        console.log(this.documentacaoSuporte)

          },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            // console.log(error.status)
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.documentacaoSuporteUrl = []
            }
        }
      }
      return new Promise<void>((resolve) => {resolve();});
    }



    saveAnexoNfProdutoOriginal(event: any): void {
      var files = event.target.files;

      for (var i = 0; i < files.length; i++) {
        this.nfProdutoOriginal.push(files[i])
        console.log(this.nfProdutoOriginal)

      }
    }


    removeFileNfProdutoOriginal(file: File) {
      const index: number = this.nfProdutoOriginal.indexOf(file);
      this.nfProdutoOriginal.splice(index, 1)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Arquivo Removido com Sucesso.',
        showConfirmButton: false,
        timer: 1500
      })
      if(this.nfProdutoOriginal.length == 0){
        this.formularioLancamentos.controls['nfProdutoOriginal'].markAsTouched()
        this.formularioLancamentos.get('nfProdutoOriginal')?.setValue('')
      }
    }

    async getPipesUrlNfProdutoOriginal(files: any) {
      this.erro = false
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = async () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")

          fileContent = fileContent.substring(fileContentStart + 1)
          fileContent = fileContent.toString()

          var binaryString = window.atob(fileContent);
          var byteArray = new Uint8Array(binaryString.length);
          for (var i=0; i<binaryString.length; i++){
          byteArray[i] += binaryString.charCodeAt(i);
         }/*from w  ww .  j a v a 2  s  .c  o  m*/

         let urlResposta:any = await this.pipefy.getFileUrl3(byteArray, fileName)


            var fileUrlTemp = "";
            var startIndex = 0;

            if (urlResposta.indexOf("orgs") > 0) {
              startIndex = urlResposta.indexOf("orgs/");
            } else {
              startIndex = urlResposta.indexOf("uploads/");
            }

            var endIndex = urlResposta.indexOf("?");
            fileUrlTemp = urlResposta.substring(startIndex, endIndex);

            this.nfProdutoOriginalUrl.push(fileUrlTemp)
        console.log(this.nfProdutoOriginal)

          },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            // console.log(error.status)
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.nfProdutoOriginalUrl = []
            }
        }
      }
      return new Promise<void>((resolve) => {resolve();});
    }

    saveListaDeItensDaPO(event: any): void {
      var files = event.target.files;

      for (var i = 0; i < files.length; i++) {
        this.listaDeItensDaPo.push(files[i])
        console.log(this.listaDeItensDaPo)

      }
    }

    removeListaDeItensDaPO(file: File) {
      const index: number = this.listaDeItensDaPo.indexOf(file);
      this.listaDeItensDaPo.splice(index, 1)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Arquivo Removido com Sucesso.',
        showConfirmButton: false,
        timer: 1500
      })
      if(this.listaDeItensDaPo.length == 0){
        this.formularioLancamentos.controls['lista_de_itens_da_po'].markAsTouched()
        this.formularioLancamentos.get('lista_de_itens_da_po')?.setValue('')
      }
    }

    async getPipesUrlListaItensDaPo(files: any) {
      this.erro = false
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = async () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")

          fileContent = fileContent.substring(fileContentStart + 1)
          fileContent = fileContent.toString()

          var binaryString = window.atob(fileContent);
          var byteArray = new Uint8Array(binaryString.length);
          for (var i=0; i<binaryString.length; i++){
          byteArray[i] += binaryString.charCodeAt(i);
         }/*from w  ww .  j a v a 2  s  .c  o  m*/

         let urlResposta:any = await this.pipefy.getFileUrl3(byteArray, fileName)


            var fileUrlTemp = "";
            var startIndex = 0;

            if (urlResposta.indexOf("orgs") > 0) {
              startIndex = urlResposta.indexOf("orgs/");
            } else {
              startIndex = urlResposta.indexOf("uploads/");
            }

            var endIndex = urlResposta.indexOf("?");
            fileUrlTemp = urlResposta.substring(startIndex, endIndex);

            this.listaDeItensDaPoUrl.push(fileUrlTemp)
        console.log(this.listaDeItensDaPo)

          },
          (error: any) => {
            this.erro = true
            this.spinner.hide()
            // console.log(error.status)
            this.error_counter = this.error_counter + 1
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
                  .then(() => {
                    this.msalService.logoutRedirect()
                  }
                  )
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            }
            else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              })
              this.listaDeItensDaPoUrl = []
            }
        }
      }
      return new Promise<void>((resolve) => {resolve();});
    }

    inserirNoArrayLancamentos(event:any){
      // console.log(this.formularioLancamentos.getRawValue().informacoes_complementares_da_po)
      let valor:any = event.target.value
      // console.log(valor)
      if(this.formularioLancamentos.getRawValue().informacoes_complementares_da_po){
        let valorTratado = '"'+valor+'"'
      this.informacoesComplementaresPo.push(valorTratado)
      // console.log(this.informacoesComplementaresPo)
      }
      if(!this.formularioLancamentos.getRawValue().informacoes_complementares_da_po){
        const index: number = this.informacoesComplementaresPo.indexOf(valor);
        this.informacoesComplementaresPo.splice(index, 1)
        // console.log(this.informacoesComplementaresPo)
      }
    }

    notaComRateiosValue(event:any){
      var valor = event.target
      if(valor.checked){
        this.notaComRateioDeValoresString = 'Sim'
        // console.log(this.notaComRateioDeValoresString)
      }else{
        this.notaComRateioDeValoresString = ''
        // console.log('vazio',this.notaComRateioDeValoresString)
      }
    }

    riscoSacadoValue(event:any){
      var valor = event.target
      if(valor.checked){
        this.riscoSacadoString = 'Sim'
        // console.log(this.riscoSacadoString)
      }else{
        this.riscoSacadoString = ''
        // console.log('vazio',this.riscoSacadoString)
      }
    }

    dacteCorretoValue(event: any) {
      var valor = event.target
      if(valor.checked){
        this.dacteCorretoString = 'Sim'
        // console.log(this.dacteCorretoString)
      }else{
        this.dacteCorretoString = ''
        // console.log('vazio',this.riscoSacadoString)
      }

    }


    onDocumentoChange(event: any) {
      var valor = event.target.value
      // console.log(valor)
      if (valor == "CPF") {
        // console.log("cpf checked")
        this.formularioLancamentos.get('cpf_do_fornecedor')?.setValidators([Validators.required])
        this.formularioLancamentos.get('cpf_do_fornecedor')?.updateValueAndValidity();

        this.formularioLancamentos.get('cnpj_fornecedor')?.clearValidators()
        this.formularioLancamentos.get('cnpj_fornecedor')?.updateValueAndValidity();

      }

      if(valor == "CNPJ"){
        // console.log("cnpj checked")
        this.formularioLancamentos.get('cnpj_fornecedor')?.setValidators([Validators.required])
        this.formularioLancamentos.get('cnpj_fornecedor')?.updateValueAndValidity();

        this.formularioLancamentos.get('cpf_do_fornecedor')?.clearValidators()
        this.formularioLancamentos.get('cpf_do_fornecedor')?.updateValueAndValidity();
      }
    }


    onEmpresaChange(event: any) {
      let empresa = event.target.value
      // console.log(empresa)

      if (empresa === 'Agrichem') {
        this.formularioLancamentos.get('departamento')?.setValidators([Validators.required])
        this.formularioLancamentos.get('departamento')?.updateValueAndValidity();
      }

      if (empresa === '' || empresa === 'TecAgro (FAG)' || empresa === 'TecAgro (SGL)' || empresa === 'TecAgro (FCR)' || empresa === 'Nutrien') {
        this.formularioLancamentos.get('departamento')?.clearValidators()
        this.formularioLancamentos.get('departamento')?.updateValueAndValidity();
      }
    }

    onCnpjEmpresaNutrienChange(event: any) {
      let cnpjSelecionado = event.target.value;
      let empresaSelecionada = this.listaEmpresasCnpjs.filter(nome => nome.cnpj === cnpjSelecionado);
      this.formularioLancamentos.get('nome_empresa_nutrien')?.setValue(empresaSelecionada[0].nome);
    }

    onPagamentoUrgenteChange(event:any){
      let pagamentoUrgente = event.target.value
      // console.log("Pagamento Urgente : ", pagamentoUrgente)

      if (pagamentoUrgente === 'Sim'){
        this.formularioLancamentos.get('motivo_da_excecao')?.setValidators([Validators.required])
        this.formularioLancamentos.get('motivo_da_excecao')?.updateValueAndValidity();
      }

      if(pagamentoUrgente === 'Não'){
        this.formularioLancamentos.get('motivo_da_excecao')?.clearValidators()
        this.formularioLancamentos.get('motivo_da_excecao')?.updateValueAndValidity();
      }
    }

    onTipoDeNotaChange(event:any){
      // console.log(event)
      let tipo_de_nota = event.target.value
      // console.log(tipo_de_nota)

      // if(tipo_de_nota === 'Reembolso' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias'){
        if(tipo_de_nota === 'Jurídico' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias/Imposto/Taxas'){

        // console.log("entrei aqui REEMBOLSO")
        this.formularioLancamentos.get('conta_contabil')?.setValidators([Validators.required])
        this.formularioLancamentos.get('conta_contabil')?.updateValueAndValidity()
        this.formularioLancamentos.get('qual_a_quantidade_de_cte')?.clearValidators()
        this.formularioLancamentos.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
      }
      // if(tipo_de_nota === 'Frete' || tipo_de_nota === 'CTE') {
        if(tipo_de_nota === 'Frete/CTE') {
          // console.log("entrei aqui FRETE")
          this.formularioLancamentos.get('qual_a_quantidade_de_cte')?.setValidators([Validators.required])
          this.formularioLancamentos.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
          this.formularioLancamentos.get('conta_contabil')?.clearValidators()
        this.formularioLancamentos.get('conta_contabil')?.updateValueAndValidity()
        }

      // if(tipo_de_nota == '' ||
      //    tipo_de_nota == 'Pagamento serviço PO' ||
      //    tipo_de_nota == 'Pagamento material PO com NFE e XML' ||
      //    tipo_de_nota == 'Pagamento material PO com NFE' ||
      //    tipo_de_nota == 'Pagamento material sem PO' ||
      //    tipo_de_nota == 'Boleto' ||
      //    tipo_de_nota == 'Materia Prima' ||
      //    tipo_de_nota == 'Sem valor fiscal' ||
      //    tipo_de_nota == 'Uso e consumo' ||
      //    tipo_de_nota == 'Importação' ||
      //    tipo_de_nota == 'Ativo fixo (AFE)' ||
      //    tipo_de_nota == 'Retorno Armazem' ||
      //    tipo_de_nota == 'Outras NFs sem PO' ||
      //    tipo_de_nota == 'DANFE' ||
      //    tipo_de_nota == 'NFPS' ||
      //    tipo_de_nota == 'DANFE (DIFAL)' ||
      //    tipo_de_nota == 'Fatura' ||
      //    tipo_de_nota == 'Imposto' ||
      //    tipo_de_nota == 'Cartório' ||
      //    tipo_de_nota == 'Conta de consumo' ){

          if(tipo_de_nota == '' ||
          tipo_de_nota == 'Ativo fixo (AFE)' ||
          tipo_de_nota == 'Benefícios' ||
          tipo_de_nota == 'Cartório' ||
          tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
          tipo_de_nota == 'Correio' ||
          tipo_de_nota == 'Escrituração - Não gera pagamento' ||
          tipo_de_nota == 'Importação' ||
          tipo_de_nota == 'Incentivo - Doação' ||
          tipo_de_nota == 'Industrialização' ||
          tipo_de_nota == 'Jurídico' ||
          tipo_de_nota == 'Material (DANFE)' ||
          tipo_de_nota == 'Nota de Crédito' ||
          tipo_de_nota == 'Outras NFs sem PO' ||
          tipo_de_nota == 'Sem valor fiscal' ||
          tipo_de_nota == 'Serviço'
          ){

        // console.log("oi")
        this.formularioLancamentos.get('conta_contabil')?.clearValidators()
        this.formularioLancamentos.get('conta_contabil')?.updateValueAndValidity()
        this.formularioLancamentos.get('qual_a_quantidade_de_cte')?.clearValidators()
        this.formularioLancamentos.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
      }

      if(tipo_de_nota == 'Aluguel'){
        Swal.fire({
          // title: 'Você será redirecionado!',
          text: 'Para contas que não estiverem em nome da Nutrien, favor, anexar o contrato de referência no campo anexo documentação suporte.',
          icon: 'info',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#88D600',
        }).then(()=>{
          this.formularioLancamentos.get('anexo_documentacao_suporte')?.setValidators([Validators.required])
          this.formularioLancamentos.get('anexo_documentacao_suporte')?.updateValueAndValidity();
        })
      }

      if(tipo_de_nota != 'Aluguel'){
        this.formularioLancamentos.get('anexo_documentacao_suporte')?.clearValidators();
        this.formularioLancamentos.get('anexo_documentacao_suporte')?.updateValueAndValidity();
      }
    }

    onDataDeVencimentoChange(event: any){
      var data = event.target.value
      Swal.fire({
        // title: 'Atenção',
        width: 600,
        html: '<h6 style="font-size:20px;color:#88D600;font-weight:900">Pagamentos Gerais  (Fornecedores)</h6><br>'+
              '<h6 style="font-size:20px;color:#88D600;font-weight:900">Criação de solicitação de pagamento:</h6>'+
              '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>Mínimo de 10 dias de antecedência às datas de pagamento.</p>'+
              '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>É requerido o envio imediato dos documentos após seu recebimento.</p><br>'+
              '<h6 style="font-size:20px;color:#88D600;font-weight:900">Condição de Pagamento</h6>'+
              '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>Estabelecido no pedido de compra a contar da data de emissão do documento.</p><br>'+
              '<h6 style="font-size:20px;color:#88D600;font-weight:900">Efetivação do Pagamento</h6>'+
              '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>Atendidas as condições acima e o processo de abertura no NBS/pedido não apresente inconsistências, ocorrerá na próxima data de pagamento (15 ou 25) (feriado ou fim de semana no próximo dia útil).</p>',
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#88D600',
        padding:'10px 30px 30px',
        iconColor:'#88D600'
      })
    }


    resetformLancamentos() {
      for (let field in this.formularioLancamentos.controls) {
        this.formularioLancamentos.controls[field].setValue('');
      }
      this.formularioLancamentos.markAsUntouched()

      this.arquivosDeRateio = [];
      this.notas = [];
      this.boletos = []
      this.xml = []
      this.documentacaoSuporte = [];
      this.listaDeItensDaPo = [];

      this.arquivosDeRateioUrl = [];
      this.notasUrl = []
      this.boletosUrl = []
      this.xmlUrl = []
      this.documentacaoSuporteUrl = [];
      this.listaDeItensDaPoUrl = [];

      this.formularioLancamentos.get('cnpj_fornecedor')?.clearValidators()
      this.formularioLancamentos.get('cnpj_fornecedor')?.updateValueAndValidity();
      this.formularioLancamentos.get('cpf_do_fornecedor')?.clearValidators()
      this.formularioLancamentos.get('cpf_do_fornecedor')?.updateValueAndValidity();
      this.formularioLancamentos.get('departamento')?.clearValidators()
      this.formularioLancamentos.get('departamento')?.updateValueAndValidity();

      this.notaComRateioDeValoresString = ''
      this.riscoSacadoString = ''

      this.emailApi = this.userInfo.getUserEmail()
      this.userNameApi = this.userInfo.getName()

      this.formularioLancamentos.get('email')?.setValue(this.emailApi)
      this.formularioLancamentos.get('nome')?.setValue(this.userNameApi)


      if (this.formularioLancamentos.getRawValue().email != '') {
        // this.formularioLancamentos.controls['email'].disable()
      }
      if (this.formularioLancamentos.getRawValue().email == undefined) {
        // this.formularioLancamentos.controls['email'].enable()
        this.formularioLancamentos.get('email')?.setValue('')

      }

      if (this.formularioLancamentos.getRawValue().nome != '') {
        // this.formularioLancamentos.controls['nome'].disable()
      }
      if (this.formularioLancamentos.getRawValue().nome == undefined) {
        // this.formularioLancamentos.controls['nome'].enable()
        this.formularioLancamentos.get('nome')?.setValue('')
      }

      this.resetform()
    }

    abrirPipePublicoLancamentos() {
      this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
    }



  // ************************************* REGRAS OCR *******************************************************************


    save2(event: any): void {
      console.log('inicio save2')
      this.clearFieldsOCR();
      var files = event.target.files;
      console.log('arquivosNf: ', this.arquivosNf)
      console.log('arquivosNfUrl: ', this.arquivosNfUrl)

      for (var i = 0; i < files.length; i++) {
        for (var i = 0; i < files.length; i++) {
          var fileContent = files[i]
          let fileName = files[i].name
          this.fileName2 = files[i].name;
          const reader = new FileReader();
          reader.readAsDataURL(fileContent);
          reader.onload = () => {
            fileContent = reader.result?.toString()
            var fileContentStart = fileContent.indexOf(",")
            fileContent = fileContent.substring(fileContentStart + 1)
            // console.log(fileContent)
            // console.log(fileName)
            this.spinner.show()
            this.pipefy.testeMost(fileContent).subscribe((res:any)=>{
              console.log(res)

              if(res.body.result.length > 0){

                let tipo = this.formulario.getRawValue().tipo_de_nota
                let chave = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'chave_acesso' && t.score >= 0.8)
                this.chaveAcesso = chave[0] && chave[0].value ? chave[0].value : '';

                if (tipo == 'Material Compras e aquisição' || tipo == 'Material (DANFE)') {
                  let tag =  res.body.result[0].tags.findIndex((element: string | string[]) => element.includes("bra-danfe"))
                  console.log(tag)
                  if (tag == -1) {
                    Swal.fire({
                      title: 'Atenção!',
                      text: 'A nota fiscal inserida não é do tipo informado.',
                      icon: 'info',
                      confirmButtonText: 'Ok',
                      confirmButtonColor: '#88D600',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    })
                  }
                } else if (tipo == 'Serviço') {
                  let tag =  res.body.result[0].tags.findIndex((element: string | string[]) => element.includes("carta-correcao-eletronica") || element.includes("bra-nfe"))
                  console.log(tag)
                  if (tag == -1) {
                    Swal.fire({
                      title: 'Atenção!',
                      text: 'A nota fiscal inserida não é do tipo informado.',
                      icon: 'info',
                      confirmButtonText: 'Ok',
                      confirmButtonColor: '#88D600',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                    })
                  }
                }

                switch (tipo) {

                  // testar outros tipos de nova e inserir no filtro

                  case "Contas de consumo (Luz, água, telefone, internet, gás)":
                    var cpf_cnpj_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cnpj' && t.score >= 0.8) || (t.name == 'cpf_cnpj_prestador' && t.score >= 0.8))
                    var cpf_cnpj = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'cpf_cnpj' && t.score >= 0.8)
                    var dataEmissao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_emissao' && t.score >= 0.8)
                    var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                    var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' ||t.name == 'valor_a_pagar') && t.score >= 0.8)
                    var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'numero_documento' && t.score >= 0.8) || (t.name == 'numero_nota_fiscal' && t.score >= 0.8))

                    cpf_cnpj_emitente = this.verificaCampoOcr(cpf_cnpj_emitente)
                    cpf_cnpj = this.verificaCampoOcr(cpf_cnpj)
                    dataEmissao = this.verificaCampoOcr(dataEmissao)
                    dataVencimento =this.verificaCampoOcr(dataVencimento)
                    valorTotal = this.verificaCampoOcr(valorTotal)
                    numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)

                    console.log(cpf_cnpj_emitente)
                    console.log(cpf_cnpj)
                    console.log(dataEmissao)
                    console.log(dataVencimento)
                    console.log(valorTotal)
                    console.log(numNotaFiscal)

                    this.formulario.controls['data_de_emiss_o']?.setValue(dataEmissao[0].value);
                    this.formulario.controls['data_de_vencimento']?.setValue(dataVencimento[0].value);
                    this.formataValorDecimal(valorTotal[0].value);
                    this.formulario.controls['valor_da_fatura']?.setValue(this.valorFaturaAlterado);
                    this.formulario.controls['numero_da_nota_fiscal']?.setValue(numNotaFiscal[0].value);

                    cpf_cnpj_emitente && cpf_cnpj_emitente[0].value !== '' ? this.searchFornecedor2(cpf_cnpj_emitente[0].value) : this.spinner.hide();
                    cpf_cnpj && cpf_cnpj[0].value !== '' ? this.searchTomador2(cpf_cnpj[0].value) : this.spinner.hide();
                    break;

                  case "Material Compras e aquisição":
                  case "Material (DANFE)":
                  case "Serviço":
                  case "Sem valor fiscal":
                  case "Beneficios":
                  case "Escrituração - Não gera pagamento":
                  case "Importação":

                    // serviço = sem valor fiscal = Importação = Escrituração = Beneficios
                    var cpf_cnpj_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cpf_cnpj_emitente' || t.name == 'cnpj' || t.name == 'cpf_cnpj_prestador') && t.score >= 0.8)
                    var cpf_cnpj = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cpf_cnpj' || t.name == 'cnpj_cpf') && t.score >= 0.8)
                    var dataEmissao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_emissao' && t.score >= 0.8)
                    var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                    var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' ||t.name == 'valor_a_pagar') && t.score >= 0.8)
                    var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'numero_documento' && t.score >= 0.8)

                    cpf_cnpj_emitente = this.verificaCampoOcr(cpf_cnpj_emitente)
                    cpf_cnpj = this.verificaCampoOcr(cpf_cnpj)
                    dataEmissao = this.verificaCampoOcr(dataEmissao)
                    dataVencimento =this.verificaCampoOcr(dataVencimento)
                    valorTotal = this.verificaCampoOcr(valorTotal)
                    numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)

                    this.formulario.controls['data_de_emiss_o']?.setValue(dataEmissao[0].value);
                    this.formulario.controls['data_de_vencimento']?.setValue(dataVencimento[0].value);
                    this.formataValorDecimal(valorTotal[0].value);
                    this.formulario.controls['valor_da_fatura']?.setValue(this.valorFaturaAlterado);
                    this.formulario.controls['numero_da_nota_fiscal']?.setValue(numNotaFiscal[0].value);

                    cpf_cnpj_emitente && cpf_cnpj_emitente[0].value !== '' ? this.searchFornecedor2(cpf_cnpj_emitente[0].value) : this.spinner.hide();
                    cpf_cnpj && cpf_cnpj[0].value !== '' ? this.searchTomador2(cpf_cnpj[0].value) : this.spinner.hide();
                    break;

                    case "Aluguel":
                      var cpf_cnpj_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cnpj' && t.score >= 0.8) || (t.name == 'cpf_cnpj_prestador' && t.score >= 0.8) || (t.name == 'cpf_cnpj_beneficiario' && t.score >= 0.8))
                      var cpf_cnpj = res.body.result[0].fields.filter((t: { name: string; score: number }) =>t.name == 'cpf_cnpj' && t.score >= 0.8)
                      var dataEmissao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_emissao' && t.score >= 0.8)
                      var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                      var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' ||t.name == 'valor_a_pagar') && t.score >= 0.8)
                      var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'numero_documento' && t.score >= 0.8) || (t.name == 'numero_nota_fiscal' && t.score >= 0.8))

                      cpf_cnpj_emitente = this.verificaCampoOcr(cpf_cnpj_emitente)
                      cpf_cnpj = this.verificaCampoOcr(cpf_cnpj)
                      dataEmissao = this.verificaCampoOcr(dataEmissao)
                      dataVencimento =this.verificaCampoOcr(dataVencimento)
                      valorTotal = this.verificaCampoOcr(valorTotal)
                      numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)

                      console.log(cpf_cnpj_emitente)
                      console.log(cpf_cnpj)
                      console.log(dataEmissao)
                      console.log(dataVencimento)
                      console.log(valorTotal)
                      console.log(numNotaFiscal)

                      this.formulario.controls['data_de_emiss_o']?.setValue(dataEmissao[0].value);
                      this.formulario.controls['data_de_vencimento']?.setValue(dataVencimento[0].value);
                      this.formataValorDecimal(valorTotal[0].value);
                      this.formulario.controls['valor_da_fatura']?.setValue(this.valorFaturaAlterado);


                      this.formulario.controls['numero_da_nota_fiscal']?.setValue(numNotaFiscal[0].value);

                      cpf_cnpj_emitente && cpf_cnpj_emitente[0].value !== '' ? this.searchFornecedor2(cpf_cnpj_emitente[0].value) : this.spinner.hide();
                      cpf_cnpj && cpf_cnpj[0].value !== '' ? this.searchTomador2(cpf_cnpj[0].value) : this.spinner.hide();
                      break;

                    // case "Importação":

                    // var dataVencimento = res.body.result[0].fields.filter((t: { name: string; }) => t.name == 'data_vencimento')
                    // var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; }) => t.name == 'numero_documento')
                    // var valorTotal = res.body.result[0].fields.filter((t: { name: string; }) => t.name == 'valor_total_nota')


                    // dataVencimento =this.verificaCampoOcr(dataVencimento)
                    // valorTotal = this.verificaCampoOcr(valorTotal)
                    // numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)

                    // console.log(dataVencimento)
                    // console.log(valorTotal)

                    // this.formulario.controls['data_de_vencimento']?.setValue(dataVencimento[0].value);
                    // this.formulario.controls['valor_da_fatura']?.setValue(this.formataValorDecimal(valorTotal[0].value))
                    // this.formulario.controls['numero_da_nota_fiscal']?.setValue(numNotaFiscal[0].value);


                    // this.spinner.hide()
                    // break;


                  default:
                    this.spinner.hide()
                    break;
                }

                res.body.result[0].fields.forEach(function(item: any){ delete item.stdName });
                this.resNotaJsonMost = res.body.result[0].fields;

              } else {
                this.spinner.hide()
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Não foi possível fazer a leitura do documento enviado. Por favor, faça o preenchimento manualmente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              }
            },(error: any) => {
              this.spinner.hide()
              console.log(error.status)
              console.log(error)
                Swal.fire({
                  title: 'Ops!',
                  text: 'Ocorreu um erro na sua solicitação, verifique seu arquivo e tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                })
              })
          }
        }
      }
      console.log('fim save2')

    }

    save3(event: any): void {
      console.log('inicio save3')
      console.log('arquivosNfBoleto: ', this.arquivosNfBoleto)
      console.log('arquivosNfBoletoUrl: ', this.arquivosNfBoletoUrl)
      this.arquivosBoleto = [];
      this.arquivosBoletoUrl = [];
      var files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        for (var i = 0; i < files.length; i++) {
          var fileContent = files[i]
          let fileName = files[i].name
          this.fileName3 = files[i].name;
          // if (this.fileName2 == this.fileName3) {
          //   Swal.fire({
          //     title: 'Ops!',
          //     text: 'Os arquivos Anexo Nota Fiscal/Documento e Anexo Boleto devem ser diferentes, por favor verifique e tente novamente.',
          //     icon: 'error',
          //     confirmButtonText: 'Ok',
          //     confirmButtonColor: '#88D600',
          //     allowOutsideClick: false,
          //     allowEscapeKey: false
          //   })
          //   this.formulario.controls['anexo_boleto_ipe']?.setValue('');
          //   this.formulario.controls['anexo_boleto_ipe'].updateValueAndValidity;

          //   this.arquivosBoleto = [];
          //   this.arquivosBoletoUrl = [];

          //   this.erroArquivosNfBoleto = true;
          //   return
          // } else {
            this.erroArquivosNfBoleto = false;
            const reader = new FileReader();
            reader.readAsDataURL(fileContent);
            reader.onload = () => {
              fileContent = reader.result?.toString()
              var fileContentStart = fileContent.indexOf(",")
              fileContent = fileContent.substring(fileContentStart + 1)
              this.spinner.show()
              this.pipefy.testeMost(fileContent).subscribe((res:any)=>{
                console.log(res)
                if(res.body.result.length > 0){

                  var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                  var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota') && t.score >= 0.8)

                  dataVencimento =this.verificaCampoOcr(dataVencimento)
                  valorTotal = this.verificaCampoOcr(valorTotal)

                  console.log(dataVencimento)
                  console.log(valorTotal)

                  this.formulario.controls['data_de_vencimento']?.setValue(dataVencimento[0].value);
                  if (valorTotal[0].value &&
                      (this.formulario.getRawValue().valor_da_fatura == '' || this.formulario.getRawValue().valor_da_fatura == '0,00' || this.formulario.getRawValue().valor_da_fatura == undefined)
                    ) {
                    this.formataValorDecimal(valorTotal[0].value);
                    this.formulario.controls['valor_da_fatura']?.setValue(this.valorFaturaAlterado);
                  }

                  res.body.result[0].fields.forEach(function(item: any){ delete item.stdName });
                  this.resBoletoJsonMost = res.body.result[0].fields;

                  this.spinner.hide()

                } else {
                  this.spinner.hide()
                  Swal.fire({
                    title: 'Desculpe!',
                    text: 'Não foi possível fazer a leitura do documento enviado. Por favor, faça o preenchimento manualmente.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#88D600',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  })
                }
              },(error: any) => {
                this.spinner.hide()
                console.log(error.status)
                console.log(error)
                  Swal.fire({
                    title: 'Ops!',
                    text: 'Ocorreu um erro na sua solicitação, verifique seu arquivo e tente novamente.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#88D600',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  })
                })
            }
          // }
        }
      }
      console.log('fim save3')

    }

    // verifica se retorno do filtro do OCR está vazio
    verificaCampoOcr(campoOcr: any){
      var retorno = campoOcr
      console.log(retorno)
      if(retorno.length == 0){
        console.log("entrei aqui")
        retorno = [
          {
            name:'',
            value:''
          }
        ]
        return retorno
      }
      return retorno
    }

    clearFieldsOCR() {
      console.log('entrei clear fields')
      console.log('1 this.arquivosNf:', this.arquivosNf)
      console.log('1 this.arquivosNfUrl:', this.arquivosNfUrl)
      console.log('1 this.arquivosNfBoleto:', this.arquivosNfBoleto)
      console.log('1 this.arquivosNfBoletoUrl:', this.arquivosNfBoletoUrl)

      this.arquivosNf = [];
      this.arquivosNfUrl = [];
      this.arquivosNfBoleto = [];
      this.arquivosNfBoletoUrl = [];
      this.fornecedorSelecionado = {nome: '', codErp: '', cnpj: '', cardId: ''};
      this.tomadorSelecionado = {nome: '', codErp: '', cnpj: '', cardId: ''};
      this.listaFornecedores = [];
      this.listaTomadores = [];
      this.valorFaturaAlterado = '';
      this.formulario.controls['valor_da_fatura']?.setValue('');
      this.formulario.controls['data_de_emiss_o']?.setValue('');
      this.formulario.controls['data_de_vencimento']?.setValue('');
      this.formulario.controls['numero_da_nota_fiscal']?.setValue('');

      console.log('2 this.arquivosNf:', this.arquivosNf)
      console.log('2 this.arquivosNfUrl:', this.arquivosNfUrl)
      console.log('2 this.arquivosNfBoleto:', this.arquivosNfBoleto)
      console.log('2 this.arquivosNfBoletoUrl:', this.arquivosNfBoletoUrl)
    }


}


// teste
