import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.interface';


@Component({
  selector: 'app-retificacao-obrigacoes',
  templateUrl: './retificacao-obrigacoes.component.html',
  styleUrls: ['./retificacao-obrigacoes.component.css']
})
export class RetificacaoObrigacoesComponent implements OnInit {

  fields: FormField[] = [];

  constructor() { }

  ngOnInit(): void {

    this.fields = [
      {
        "label": "Divisão fiscal",
        "name": "divis_o_fiscal",
        "select": [
          {
            "type": "option",
            "name": "Fiscal diretos",
            "value": "Fiscal diretos"
          },
          {
            "type": "option",
            "name": "Fiscal indiretos",
            "value": "Fiscal indiretos"
          },
          {
            "type": "option",
            "name": "Fiscal indiretos & Fiscal diretos",
            "value": "Fiscal indiretos & Fiscal diretos"
          }
        ],
        "type": "select",
        "validators": Validators.required
      },
      {
        "label": "Período da retificação ",
        "name": "per_odo_da_retifica_o",
        "select": [
          {
            "type": "option",
            "name": "Retificações antes de 07/2022",
            "value": "Retificações antes de 07/2022"
          },
          {
            "type": "option",
            "name": "Retificações após 07/2022-NBS",
            "value": "Retificações após 07/2022-NBS"
          }
        ],
        "type": "select",
        "validators": Validators.required
      },
      {
        "label": "Houveram retificações anteriores?",
        "name": "houveram_retifica_es_anteriores",
        "select": [
          {
            "type": "option",
            "name": "Sim",
            "value": "Sim",
            "fields": [
              {
                "label": "Detalhamento da retificação anterior",
                "name": "detalhamento_da_retifica_o_anterior",
                "type": "textArea",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "label": "Número do recibo da entrega Original ou retificada",
                "name": "n_mero_do_recibo_da_entrega_original_ou_retificada",
                "type": "text",
                "validators": [Validators.required, Validators.maxLength(1000)]
              }
            ]
          },
          {
            "type": "option",
            "name": "Não",
            "value": "Não"
          }
        ],
        "type": "select",
        "validators": Validators.required
      },
      {
        "label": "Empresa",
        "name": "empresa",
        "select": [
          {
            "type": "option",
            "name": "Agrichem",
            "value": "Agrichem",
            "fields": [
              {
                "label": "Filiais - Agrichem",
                "name": "filiais_agrichem",
                "select": [
                  {
                    "type": "option",
                    "name": "Matriz-Agrichem -Ribeirão Preto-0001-92-Sp",
                    "value": "Matriz-Agrichem -Ribeirão Preto-0001-92-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrichem -Ribeirão Preto-0004-35-Sp",
                    "value": "Filial-Agrichem -Ribeirão Preto-0004-35-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Agrichem",
                    "value": "Agrichem"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            "type": "option",
            "name": "Agrosema",
            "value": "Agrosema",
            "fields": [
              {
                "label": "Filiais - Agrosema",
                "name": "filiais_agrosema",
                "select": [
                  {
                    "type": "option",
                    "name": "Agrosema",
                    "value": "Agrosema"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Alfenas-0031-31-Mg",
                    "value": "Filial-Agrosema -Alfenas-0031-31-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Araxá-0029-17-Mg",
                    "value": "Filial-Agrosema -Araxá-0029-17-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Capão Bonito-0005-40-Sp",
                    "value": "Filial-Agrosema -Capão Bonito-0005-40-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Coromandel-0026-74-Mg",
                    "value": "Filial-Agrosema -Coromandel-0026-74-Mg",
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Elias Fausto-0012-79-Sp",
                    "value": "Filial-Agrosema -Elias Fausto-0012-79-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Ibiá-0018-64-Mg",
                    "value": "Filial-Agrosema -Ibiá-0018-64-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Ibiá-0028-36-Mg",
                    "value": "Filial-Agrosema -Ibiá-0028-36-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Indaiatuba-0011-98-Sp",
                    "value": "Filial-Agrosema -Indaiatuba-0011-98-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Itapetininga-0008-92-Sp",
                    "value": "Filial-Agrosema -Itapetininga-0008-92-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Ituiutaba-0032-12-Mg",
                    "value": "Filial-Agrosema -Ituiutaba-0032-12-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Mogi-Guaçu-0007-01-Sp",
                    "value": "Filial-Agrosema -Mogi-Guaçu-0007-01-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Patos De Minas-0015-11-Mg",
                    "value": "Filial-Agrosema -Patos De Minas-0015-11-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Patrocínio-0027-55-Mg",
                    "value": "Filial-Agrosema -Patrocínio-0027-55-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -São Gotardo-0030-50-Mg",
                    "value": "Filial-Agrosema -São Gotardo-0030-50-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -São Miguel Arcanjo-0006-20-Sp",
                    "value": "Filial-Agrosema -São Miguel Arcanjo-0006-20-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Taquarituba-0013-50-Sp",
                    "value": "Filial-Agrosema -Taquarituba-0013-50-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Uberaba-0023-21-Mg",
                    "value": "Filial-Agrosema -Uberaba-0023-21-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Uberaba-0024-02-Mg",
                    "value": "Filial-Agrosema -Uberaba-0024-02-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Uberaba-0025-93-Mg",
                    "value": "Filial-Agrosema -Uberaba-0025-93-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Uberlândia-0022-40-Mg",
                    "value": "Filial-Agrosema -Uberlândia-0022-40-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrosema -Vargem Grande Do Sul-0016-00-Sp",
                    "value": "Filial-Agrosema -Vargem Grande Do Sul-0016-00-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Matriz-Agrosema -Elias Fausto-0001-16-Sp",
                    "value": "Matriz-Agrosema -Elias Fausto-0001-16-Sp"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            "type": "option",
            "name": "Nutrien",
            "value": "Nutrien",
            "fields": [
              {
                "label": "Filiais - Nutrien",
                "name": "filiais_nutrien",
                "select": [
                  {
                    "type": "option",
                    "name": "Matriz-Nutrien-Canadá-0001-26-Ex",
                    "value": "Matriz-Nutrien-Canadá-0001-26-Ex"
                  },
                  {
                    "type": "option",
                    "name": "Matriz-Nutrien-São Paulo-0001-50-Sp",
                    "value": "Matriz-Nutrien-São Paulo-0001-50-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapetininga-0004-00-Sp",
                    "value": "Filial-Nutrien-Itapetininga-0004-00-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Araxa-0015-55-Mg",
                    "value": "Filial-Nutrien-Araxa-0015-55-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Cristalina-0020-12-Go",
                    "value": "Filial-Nutrien-Cristalina-0020-12-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Morrinhos-0021-01-Go",
                    "value": "Filial-Nutrien-Morrinhos-0021-01-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Angatuba-0014-74-Sp",
                    "value": "Filial-Nutrien-Angatuba-0014-74-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapeva-0005-83-Sp",
                    "value": "Filial-Nutrien-Itapeva-0005-83-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Avare-0018-06-Sp",
                    "value": "Filial-Nutrien-Avare-0018-06-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Limeira-0019-89-Sp",
                    "value": "Filial-Nutrien-Limeira-0019-89-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Assis-0026-08-Sp",
                    "value": "Filial-Nutrien-Assis-0026-08-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapetininga Col Novo-0022-84-Sp",
                    "value": "Filial-Nutrien-Itapetininga Col Novo-0022-84-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Indaiatuba-0056-23-Sp",
                    "value": "Filial-Nutrien-Indaiatuba-0056-23-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Capão Bonito-0041-47-Sp",
                    "value": "Filial-Nutrien-Capão Bonito-0041-47-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Elias Fausto-0039-22-Sp",
                    "value": "Filial-Nutrien-Elias Fausto-0039-22-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Elias Fausto-0042-28-Sp",
                    "value": "Filial-Nutrien-Elias Fausto-0042-28-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Ibiá-0025-27-Mg",
                    "value": "Filial-Nutrien-Ibiá-0025-27-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapetininga-0040-66-Sp",
                    "value": "Filial-Nutrien-Itapetininga-0040-66-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Mogi-Guaçu-0051-19-Sp",
                    "value": "Filial-Nutrien-Mogi-Guaçu-0051-19-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Patos De Minas-0036-80-Mg",
                    "value": "Filial-Nutrien-Patos De Minas-0036-80-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-São Miguel Arcanjo-0052-08-Sp",
                    "value": "Filial-Nutrien-São Miguel Arcanjo-0052-08-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Taquarituba-0050-38-Sp",
                    "value": "Filial-Nutrien-Taquarituba-0050-38-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Vargem Grande Do Sul-0017-17-Sp",
                    "value": "Filial-Nutrien-Vargem Grande Do Sul-0017-17-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Paracatu-0027-99-Sp",
                    "value": "Filial-Nutrien-Paracatu-0027-99-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Mogi Mirim-0028-70-Sp",
                    "value": "Filial-Nutrien-Mogi Mirim-0028-70-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Uberlândia-0037-60-Mg",
                    "value": "Filial-Nutrien-Uberlândia-0037-60-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Uberaba-0032-56-Mg",
                    "value": "Filial-Nutrien-Uberaba-0032-56-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Uberaba-0054-61-Mg",
                    "value": "Filial-Nutrien-Uberaba-0054-61-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Uberaba-0053-80-Mg",
                    "value": "Filial-Nutrien-Uberaba-0053-80-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Coromandel-0033-37-Mg",
                    "value": "Filial-Nutrien-Coromandel-0033-37-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Patrocinio-0044-90-Mg",
                    "value": "Filial-Nutrien-Patrocinio-0044-90-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Aracatuba-0029-50-Sp",
                    "value": "Filial-Nutrien-Aracatuba-0029-50-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Ibiá-0034-18-Mg",
                    "value": "Filial-Nutrien-Ibiá-0034-18-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Araxa-0045-70-Mg",
                    "value": "Filial-Nutrien-Araxa-0045-70-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-São Gotardo-0031-75-Mg",
                    "value": "Filial-Nutrien-São Gotardo-0031-75-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Alfenas-0043-09-Mg",
                    "value": "Filial-Nutrien-Alfenas-0043-09-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Ituiutaba-0035-07-Mg",
                    "value": "Filial-Nutrien-Ituiutaba-0035-07-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Unaí-0030-94-Mg",
                    "value": "Filial-Nutrien-Unaí-0030-94-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Paulinia-0038-41-Sp",
                    "value": "Filial-Nutrien-Paulinia-0038-41-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Candido Mota-0046-51-Sp",
                    "value": "Filial-Nutrien-Candido Mota-0046-51-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Limeira-0047-32-Sp",
                    "value": "Filial-Nutrien-Limeira-0047-32-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapeva-0048-13-Sp",
                    "value": "Filial-Nutrien-Itapeva-0048-13-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapolis-0049-02-Sp",
                    "value": "Filial-Nutrien-Itapolis-0049-02-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Ribeirão Preto-0055-42-Sp",
                    "value": "Filial-Nutrien-Ribeirão Preto-0055-42-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Paulinia-0024-46-Sp",
                    "value": "Filial-Nutrien-Paulinia-0024-46-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Votuporanga-0059-76-Sp",
                    "value": "Filial-Nutrien-Votuporanga-0059-76-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Avare-0057-04-Sp",
                    "value": "Filial-Nutrien-Avare-0057-04-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Campinas-0060-00-Sp",
                    "value": "Filial-Nutrien-Campinas-0060-00-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Goiânia-0061-90-Go",
                    "value": "Filial-Nutrien-Goiânia-0061-90-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Rio Verde-0058-95-Go",
                    "value": "Filial-Nutrien-Rio Verde-0058-95-Go"
                  },
                  {
                    "type": "option",
                    "name": "Matriz-Nutrien-São Paulo-0001-25-Sp",
                    "value": "Matriz-Nutrien-São Paulo-0001-25-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Nutrien Participações Ltda",
                    "value": "Nutrien Participações Ltda"
                  },
                  {
                    "type": "option",
                    "name": "Nutrien Soluções Agrícolas Ltda",
                    "value": "Nutrien Soluções Agrícolas Ltda"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            "type": "option",
            "name": "Tec Agro",
            "value": "Tec Agro",
            "fields": [
              {
                "label": "Filiais - Tec Agro",
                "name": "filiais_tec_agro",
                "select": [
                  {
                    "type": "option",
                    "name": "Apms Participações Societárias Ltda",
                    "value": "Apms Participações Societárias Ltda"
                  },
                  {
                    "type": "option",
                    "name": "Filial- Sementes Goiás-Arapongas-0008-14-Pr",
                    "value": "Filial- Sementes Goiás-Arapongas-0008-14-Pr"
                  },
                  {
                    "type": "option",
                    "name": "Filial- Sementes Goiás-Dourados-0007-33-Ms",
                    "value": "Filial- Sementes Goiás-Dourados-0007-33-Ms"
                  },
                  {
                    "type": "option",
                    "name": "Filial- Sementes Goiás-Uberlandia-0006-52-Mg",
                    "value": "Filial- Sementes Goiás-Uberlandia-0006-52-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Sementes Goiás - Tocantins-0004-90-To",
                    "value": "Filial-Sementes Goiás - Tocantins-0004-90-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Sementes Goiás - Unidade 02-0003-00-Go",
                    "value": "Filial-Sementes Goiás - Unidade 02-0003-00-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Aparecida De Goiânia (Centro De Distribuição)-0022-98-Go",
                    "value": "Filial-Tec agro - Aparecida De Goiânia (Centro De Distribuição)-0022-98-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Araxá-0010-54-Mg",
                    "value": "Filial-Tec agro - Araxá-0010-54-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Catalão-0006-78-Go",
                    "value": "Filial-Tec agro - Catalão-0006-78-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Catalão-0034-21-Go",
                    "value": "Filial-Tec agro - Catalão-0034-21-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Chapadão Do Ceu-0023-79-Go",
                    "value": "Filial-Tec agro - Chapadão Do Ceu-0023-79-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Cristalina-0030-06-Go",
                    "value": "Filial-Tec agro - Cristalina-0030-06-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Formosa-0012-16-Go",
                    "value": "Filial-Tec agro - Formosa-0012-16-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Goiânia-0008-30-Go",
                    "value": "Filial-Tec agro - Goiânia-0008-30-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Goiânia-0031-89-Go",
                    "value": "Filial-Tec agro - Goiânia-0031-89-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Goiatuba-0007-59-Go",
                    "value": "Filial-Tec agro - Goiatuba-0007-59-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Goiatuba-0029-64-Go",
                    "value": "Filial-Tec agro - Goiatuba-0029-64-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Guaraí-0020-26-To",
                    "value": "Filial-Tec agro - Guaraí-0020-26-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Gurupí-0014-88-To",
                    "value": "Filial-Tec agro - Gurupí-0014-88-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Itapetininga-0009-10-Sp",
                    "value": "Filial-Tec agro - Itapetininga-0009-10-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Jataí-0005-97-Go",
                    "value": "Filial-Tec agro - Jataí-0005-97-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Jataí-0024-50-Go",
                    "value": "Filial-Tec agro - Jataí-0024-50-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Jussara-0021-07-Go",
                    "value": "Filial-Tec agro - Jussara-0021-07-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Lagoa Da Confusão-0032-60-To",
                    "value": "Filial-Tec agro - Lagoa Da Confusão-0032-60-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Mineiros-0015-69-Go",
                    "value": "Filial-Tec agro - Mineiros-0015-69-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Montividiu-0002-44-Go",
                    "value": "Filial-Tec agro - Montividiu-0002-44-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Morrinhos-0026-11-Go",
                    "value": "Filial-Tec agro - Morrinhos-0026-11-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Palmas (Centro De Distribuição)-0019-92-To",
                    "value": "Filial-Tec agro - Palmas (Centro De Distribuição)-0019-92-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Palmas (Operador Logístico Verdelog)-0018-01-To",
                    "value": "Filial-Tec agro - Palmas (Operador Logístico Verdelog)-0018-01-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Paraíso Do Tocantins-0033-40-To",
                    "value": "Filial-Tec agro - Paraíso Do Tocantins-0033-40-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Paraúna-0003-25-Go",
                    "value": "Filial-Tec agro - Paraúna-0003-25-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Porto Nacional-0013-05-To",
                    "value": "Filial-Tec agro - Porto Nacional-0013-05-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Rio Verde-0025-30-Go",
                    "value": "Filial-Tec agro - Rio Verde-0025-30-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Rio Verde-0027-00-Go",
                    "value": "Filial-Tec agro - Rio Verde-0027-00-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Rio Verde-0028-83-Go",
                    "value": "Filial-Tec agro - Rio Verde-0028-83-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Shego-0004-06-Go",
                    "value": "Filial-Tec agro - Shego-0004-06-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Silviania-0017-20-Go",
                    "value": "Filial-Tec agro - Silviania-0017-20-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Uruaçú-0011-35-Go",
                    "value": "Filial-Tec agro - Uruaçú-0011-35-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Vianópolis-0016-40-Go",
                    "value": "Filial-Tec agro - Vianópolis-0016-40-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro Armazéns Gerais - Paraúna-0002-29-Go",
                    "value": "Filial-Tec agro Armazéns Gerais - Paraúna-0002-29-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec Agro Armazéns Gerais - Shego-0002-10-Go",
                    "value": "Filial-Tec Agro Armazéns Gerais - Shego-0002-10-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro Armazéns Gerais - Shego-0005-71-Go",
                    "value": "Filial-Tec agro Armazéns Gerais - Shego-0005-71-Go"
                  },
                  {
                    "type": "option",
                    "name": "Fronteira Armazéns Gerais Ltda",
                    "value": "Fronteira Armazéns Gerais Ltda"
                  },
                  {
                    "type": "option",
                    "name": "Fronteira Comércio Ltda ",
                    "value": "Fronteira Comércio Ltda "
                  },
                  {
                    "type": "option",
                    "name": "Matriz -Fronteira Armazéns Gerais-0001-30-Go",
                    "value": "Matriz -Fronteira Armazéns Gerais-0001-30-Go"
                  },
                  {
                    "type": "option",
                    "name": "Matriz -Holding Apms-0001-27-Go",
                    "value": "Matriz -Holding Apms-0001-27-Go"
                  },
                  {
                    "type": "option",
                    "name": "Matriz -Sementes Goiás-0001-48-Go",
                    "value": "Matriz -Sementes Goiás-0001-48-Go"
                  },
                  {
                    "type": "option",
                    "name": "Matriz -Tec agro - Rio Verde-0001-63-Go",
                    "value": "Matriz -Tec agro - Rio Verde-0001-63-Go"
                  },
                  {
                    "type": "option",
                    "name": "Sementes Goiás Ltda",
                    "value": "Sementes Goiás Ltda"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          }
        ],
        "type": "select",
        "validators": Validators.required
      },
      {
        "type": "radioHorizontal",
        "label": "Seleção da retificação",
        "name": "sele_o_da_retifica_o",
        "validators": Validators.required,
        "select": [
          {
            type: "option",
            name: "Apuração",
            value: "Apuração",
            "fields": [
              {
                "label": "Apuração",
                "name": "apura_o",
                "select": [
                  {
                    "type": "option",
                    "name": "Apuração anual",
                    "value": "Apuração anual"
                  },
                  {
                    "type": "option",
                    "name": "Apuração CSRF -  (PCC)",
                    "value": "Apuração CSRF -  (PCC)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de ICMS sobre frete",
                    "value": "Apuração de ICMS sobre frete"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de ICMS, IPI e Difal",
                    "value": "Apuração de ICMS, IPI e Difal"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de imposto de renda",
                    "value": "Apuração de imposto de renda"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e a COFINS",
                    "value": "Apuração de PIS e a COFINS"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e a COFINS  1° parte",
                    "value": "Apuração de PIS e a COFINS  1° parte"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e COFINS 2° parte",
                    "value": "Apuração de PIS e COFINS 2° parte"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de serviços prestados (ISS)",
                    "value": "Apuração de serviços prestados (ISS)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de serviços tomados (ISS)",
                    "value": "Apuração de serviços tomados (ISS)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração diferencial de alíquotas (Difal)",
                    "value": "Apuração diferencial de alíquotas (Difal)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração do estorno de crédito",
                    "value": "Apuração do estorno de crédito"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Estadual",
                    "value": "Apuração dos impostos Estadual"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Federal",
                    "value": "Apuração dos impostos Federal"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Municipais",
                    "value": "Apuração dos impostos Municipais"
                  },
                  {
                    "type": "option",
                    "name": "Apuração Funrural",
                    "value": "Apuração Funrural"
                  },
                  {
                    "type": "option",
                    "name": "Apuração INSS",
                    "value": "Apuração INSS"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRPJ e CSLL",
                    "value": "Apuração IRPJ e CSLL"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRRF",
                    "value": "Apuração IRRF"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRRF,CSRF, INSS e Funrural",
                    "value": "Apuração IRRF,CSRF, INSS e Funrural"
                  },
                  {
                    "type": "option",
                    "name": "Apuração semestral",
                    "value": "Apuração semestral"
                  },
                  {
                    "type": "option",
                    "name": "Apuração trimestral",
                    "value": "Apuração trimestral"
                  },
                  {
                    "type": "option",
                    "name": "Balancete trimestral Armazém geral",
                    "value": "Balancete trimestral Armazém geral"
                  },
                  {
                    "type": "option",
                    "name": "Balanço anual Armazém geral",
                    "value": "Balanço anual Armazém geral"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)",
                    "value": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição- IPI (Trimestral)",
                    "value": "PER/DCOMP - Restituição- IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - IPI (Trimestral)",
                    "value": "PER/DCOMP -Compensação - IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)",
                    "value": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)"
                  },
                  {
                    "type": "option",
                    "name": "Retificação de DARF - Redarf",
                    "value": "Retificação de DARF - Redarf"
                  },
                  {
                    "type": "option",
                    "name": "DCOMP- Compensação - Mensal  - Pagamento indevido",
                    "value": "DCOMP- Compensação - Mensal  - Pagamento indevido"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)",
                    "value": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição- IPI (Trimestral)",
                    "value": "PER/DCOMP - Restituição- IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - IPI (Trimestral)",
                    "value": "PER/DCOMP -Compensação - IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)",
                    "value": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)"
                  },
                  {
                    "type": "option",
                    "name": "Retificação de DARF - Redarf",
                    "value": "Retificação de DARF - Redarf"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            type: "option",
            name: "Apuração-Obrigação acessória",
            value: "Apuração-Obrigação acessória",
            "fields": [
              {
                "label": "Apuração",
                "name": "obriga_o_acess_ria",
                "select": [
                  {
                    "type": "option",
                    "name": "Apuração anual",
                    "value": "Apuração anual"
                  },
                  {
                    "type": "option",
                    "name": "Apuração CSRF - (PCC)",
                    "value": "Apuração CSRF - (PCC)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de ICMS sobre frete",
                    "value": "Apuração de ICMS sobre frete"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de ICMS, IPI e Difal",
                    "value": "Apuração de ICMS, IPI e Difal"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de imposto de renda",
                    "value": "Apuração de imposto de renda"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e a COFINS",
                    "value": "Apuração de PIS e a COFINS"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e a COFINS  1° parte",
                    "value": "Apuração de PIS e a COFINS  1° parte"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e COFINS 2° parte",
                    "value": "Apuração de PIS e COFINS 2° parte"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de serviços prestados (ISS)",
                    "value": "Apuração de serviços prestados (ISS)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de serviços tomados (ISS)",
                    "value": "Apuração de serviços tomados (ISS)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração diferencial de alíquotas (Difal)",
                    "value": "Apuração diferencial de alíquotas (Difal)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração do estorno de crédito",
                    "value": "Apuração do estorno de crédito"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Estadual",
                    "value": "Apuração dos impostos Estadual"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Federal",
                    "value": "Apuração dos impostos Federal"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Municipais",
                    "value": "Apuração dos impostos Municipais"
                  },
                  {
                    "type": "option",
                    "name": "Apuração Funrural",
                    "value": "Apuração Funrural"
                  },
                  {
                    "type": "option",
                    "name": "Apuração INSS",
                    "value": "Apuração INSS"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRPJ e CSLL",
                    "value": "Apuração IRPJ e CSLL"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRRF",
                    "value": "Apuração IRRF"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRRF,CSRF, INSS e Funrural",
                    "value": "Apuração IRRF,CSRF, INSS e Funrural"
                  },
                  {
                    "type": "option",
                    "name": "Apuração semestral",
                    "value": "Apuração semestral"
                  },
                  {
                    "type": "option",
                    "name": "Apuração trimestral",
                    "value": "Apuração trimestral"
                  },
                  {
                    "type": "option",
                    "name": "Balancete trimestral Armazém geral",
                    "value": "Balancete trimestral Armazém geral"
                  },
                  {
                    "type": "option",
                    "name": "Balanço anual Armazém geral",
                    "value": "Balanço anual Armazém geral"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)",
                    "value": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição- IPI (Trimestral)",
                    "value": "PER/DCOMP - Restituição- IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - IPI (Trimestral)",
                    "value": "PER/DCOMP -Compensação - IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)",
                    "value": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)"
                  },
                  {
                    "type": "option",
                    "name": "Retificação de DARF - Redarf",
                    "value": "Retificação de DARF - Redarf"
                  },
                  {
                    "type": "option",
                    "name": "DCOMP- Compensação - Mensal - Pagamento indevido",
                    "value": "DCOMP- Compensação - Mensal - Pagamento indevido"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)",
                    "value": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição- IPI (Trimestral)",
                    "value": "PER/DCOMP - Restituição- IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - IPI (Trimestral)",
                    "value": "PER/DCOMP -Compensação - IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)",
                    "value": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)"
                  },
                  {
                    "type": "option",
                    "name": "Retificação de DARF - Redarf",
                    "value": "Retificação de DARF - Redarf"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              },
              {
                "label": "Obrigação acessória",
                "name": "obriga_o_acess_ria",
                "select": [
                  {
                    "type": "option",
                    "name": "DAMEF - Declarção Anual do Movimento Econômico Fiscal",
                    "value": "DAMEF - Declarção Anual do Movimento Econômico Fiscal"
                  },
                  {
                    "type": "option",
                    "name": "DAPI-Declaração de Apuração e Informação do ICMS",
                    "value": "DAPI-Declaração de Apuração e Informação do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "DCTF - Declaração de Débitos Tributários Federais",
                    "value": "DCTF - Declaração de Débitos Tributários Federais"
                  },
                  {
                    "type": "option",
                    "name": "DCTFWeb - Declaração de Débitos e Créditos Tributários Federais Previdenciários",
                    "value": "DCTFWeb - Declaração de Débitos e Créditos Tributários Federais Previdenciários"
                  },
                  {
                    "type": "option",
                    "name": "DES - Declaração Eletrônica de Serviços",
                    "value": "DES - Declaração Eletrônica de Serviços"
                  },
                  {
                    "type": "option",
                    "name": "DEVEC - Declaração do Valor de Aquisição da Energia Elétrica em Ambiente de Contratação livre",
                    "value": "DEVEC - Declaração do Valor de Aquisição da Energia Elétrica em Ambiente de Contratação livre"
                  },
                  {
                    "type": "option",
                    "name": "DIME - Declaração de ICMS e Movimentos Econômicos conhecida",
                    "value": "DIME - Declaração de ICMS e Movimentos Econômicos conhecida"
                  },
                  {
                    "type": "option",
                    "name": "DIPAM - Declaração para o Índice de Participação dos Municípios",
                    "value": "DIPAM - Declaração para o Índice de Participação dos Municípios"
                  },
                  {
                    "type": "option",
                    "name": "DIPAM-A dos produtores agropecuários paulista",
                    "value": "DIPAM-A dos produtores agropecuários paulista"
                  },
                  {
                    "type": "option",
                    "name": "DIPJ- Declaração De Imposto De Renda Pessoa Jurídica",
                    "value": "DIPJ- Declaração De Imposto De Renda Pessoa Jurídica"
                  },
                  {
                    "type": "option",
                    "name": "DIRF – Declaração do Imposto sobre a Renda Retido na Fonte",
                    "value": "DIRF – Declaração do Imposto sobre a Renda Retido na Fonte"
                  },
                  {
                    "type": "option",
                    "name": "DMS- Declaração mensal de serviços- GO",
                    "value": "DMS- Declaração mensal de serviços- GO"
                  },
                  {
                    "type": "option",
                    "name": "DOT - Declaração de Operações Tributáveis -ES",
                    "value": "DOT - Declaração de Operações Tributáveis -ES"
                  },
                  {
                    "type": "option",
                    "name": "ECD- Escrituração Contábil Digital",
                    "value": "ECD- Escrituração Contábil Digital"
                  },
                  {
                    "type": "option",
                    "name": "ECF – Escrituração Contábil Fiscal -ICMS-IPI",
                    "value": "ECF – Escrituração Contábil Fiscal -ICMS-IPI"
                  },
                  {
                    "type": "option",
                    "name": "EFD Contribuições",
                    "value": "EFD Contribuições"
                  },
                  {
                    "type": "option",
                    "name": "EFD ICMS/IPI – Escrituração Contábil Digital",
                    "value": "EFD ICMS/IPI – Escrituração Contábil Digital"
                  },
                  {
                    "type": "option",
                    "name": "EFD-Reinf - Escrituração Fiscal Digital  de Retenções",
                    "value": "EFD-Reinf - Escrituração Fiscal Digital  de Retenções"
                  },
                  {
                    "type": "option",
                    "name": "E-Social",
                    "value": "E-Social"
                  },
                  {
                    "type": "option",
                    "name": "GIA – Guia de Informação e Apuração do ICMS",
                    "value": "GIA – Guia de Informação e Apuração do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "GIA — substituição tributária",
                    "value": "GIA — substituição tributária"
                  },
                  {
                    "type": "option",
                    "name": "GIAM-Guia De Informação E Apuração Mensal Do ICMS",
                    "value": "GIAM-Guia De Informação E Apuração Mensal Do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "Livro de Apuração do Lucro Real (Lalur)",
                    "value": "Livro de Apuração do Lucro Real (Lalur)"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Estadual",
                    "value": "Obrigação acessoria Estadual"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Federal",
                    "value": "Obrigação acessoria Federal"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Municipal",
                    "value": "Obrigação acessoria Municipal"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP- Pedido de Restituição, Ressarcimento ou Reembolso",
                    "value": "PER/DCOMP- Pedido de Restituição, Ressarcimento ou Reembolso"
                  },
                  {
                    "type": "option",
                    "name": "SINTEGRA – Sistema Integrado de Informações sobre Operações Interestaduais",
                    "value": "SINTEGRA – Sistema Integrado de Informações sobre Operações Interestaduais"
                  },
                  {
                    "type": "option",
                    "name": "SPED fiscal – Bloco H",
                    "value": "SPED fiscal – Bloco H"
                  },
                  {
                    "type": "option",
                    "name": "SPED fiscal – Bloco K",
                    "value": "SPED fiscal – Bloco K"
                  },
                  {
                    "type": "option",
                    "name": "VAF- Valor Adicionado Fiscal",
                    "value": "VAF- Valor Adicionado Fiscal"
                  },
                  {
                    "type": "option",
                    "name": "Outras obrigações acessórias que não estão discriminadas na lista",
                    "value": "Outras obrigações acessórias que não estão discriminadas na lista"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              },
              {
                "label": "Competência da obrigação acessória e apuração retificada",
                "name": "compet_ncia_da_obriga_o_acess_ria_e_apura_o_retificada",
                "select": [
                  {
                    "type": "option",
                    "name": "Janeiro ",
                    "value": "Janeiro "
                  },
                  {
                    "type": "option",
                    "name": "Fevereiro",
                    "value": "Fevereiro"
                  },
                  {
                    "type": "option",
                    "name": "Março",
                    "value": "Março"
                  },
                  {
                    "type": "option",
                    "name": "Abril",
                    "value": "Abril"
                  },
                  {
                    "type": "option",
                    "name": "Maio",
                    "value": "Maio"
                  },
                  {
                    "type": "option",
                    "name": "Junho",
                    "value": "Junho"
                  },
                  {
                    "type": "option",
                    "name": "Julho",
                    "value": "Julho"
                  },
                  {
                    "type": "option",
                    "name": "Agosto",
                    "value": "Agosto"
                  },
                  {
                    "type": "option",
                    "name": "Setembro",
                    "value": "Setembro"
                  },
                  {
                    "type": "option",
                    "name": "Outubro",
                    "value": "Outubro"
                  },
                  {
                    "type": "option",
                    "name": "Novembro",
                    "value": "Novembro"
                  },
                  {
                    "type": "option",
                    "name": "Dezembro",
                    "value": "Dezembro"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            type: "option",
            name: "Obrigação acessória",
            value: "Obrigação acessória",
            "fields": [
              {
                "label": "Obrigação acessória",
                "name": "obriga_o_acess_ria",
                "select": [
                  {
                    "type": "option",
                    "name": "DAMEF - Declarção Anual do Movimento Econômico Fiscal",
                    "value": "DAMEF - Declarção Anual do Movimento Econômico Fiscal"
                  },
                  {
                    "type": "option",
                    "name": "DAPI-Declaração de Apuração e Informação do ICMS",
                    "value": "DAPI-Declaração de Apuração e Informação do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "DCTF – Declaração de Débitos Tributários Federais",
                    "value": "DCTF – Declaração de Débitos Tributários Federais"
                  },
                  {
                    "type": "option",
                    "name": "DCTFWeb - Declaração de Débitos e Créditos Tributários Federais Previdenciários",
                    "value": "DCTFWeb - Declaração de Débitos e Créditos Tributários Federais Previdenciários"
                  },
                  {
                    "type": "option",
                    "name": "DES – Declaração Eletrônica de Serviços",
                    "value": "DES – Declaração Eletrônica de Serviços"
                  },
                  {
                    "type": "option",
                    "name": "DEVEC - Declaração do Valor de Aquisição da Energia Elétrica em Ambiente de Contratação livre",
                    "value": "DEVEC - Declaração do Valor de Aquisição da Energia Elétrica em Ambiente de Contratação livre"
                  },
                  {
                    "type": "option",
                    "name": "DIME - Declaração de ICMS e Movimentos Econômicos conhecida",
                    "value": "DIME - Declaração de ICMS e Movimentos Econômicos conhecida"
                  },
                  {
                    "type": "option",
                    "name": "DIPAM - Declaração para o Índice de Participação dos Municípios",
                    "value": "DIPAM - Declaração para o Índice de Participação dos Municípios"
                  },
                  {
                    "type": "option",
                    "name": "DIPAM-A dos produtores agropecuários paulista",
                    "value": "DIPAM-A dos produtores agropecuários paulista"
                  },
                  {
                    "type": "option",
                    "name": "DIPJ- Declaração De Imposto De Renda Pessoa Jurídica",
                    "value": "DIPJ- Declaração De Imposto De Renda Pessoa Jurídica"
                  },
                  {
                    "type": "option",
                    "name": "DIRF – Declaração do Imposto sobre a Renda Retido na Fonte",
                    "value": "DIRF – Declaração do Imposto sobre a Renda Retido na Fonte"
                  },
                  {
                    "type": "option",
                    "name": "DMS- Declaração mensal de serviços- GO",
                    "value": "DMS- Declaração mensal de serviços- GO"
                  },
                  {
                    "type": "option",
                    "name": "DOT - Declaração de Operações Tributáveis -ES",
                    "value": "DOT - Declaração de Operações Tributáveis -ES"
                  },
                  {
                    "type": "option",
                    "name": "ECD- Escrituração Contábil Digital",
                    "value": "ECD- Escrituração Contábil Digital"
                  },
                  {
                    "type": "option",
                    "name": "ECF – Escrituração Contábil Fiscal -ICMS-IPI",
                    "value": "ECF – Escrituração Contábil Fiscal -ICMS-IPI"
                  },
                  {
                    "type": "option",
                    "name": "EFD Contribuições",
                    "value": "EFD Contribuições"
                  },
                  {
                    "type": "option",
                    "name": "EFD ICMS/IPI – Escrituração Contábil Digital",
                    "value": "EFD ICMS/IPI – Escrituração Contábil Digital"
                  },
                  {
                    "type": "option",
                    "name": "EFD-Reinf - Escrituração Fiscal Digital  de Retenções",
                    "value": "EFD-Reinf - Escrituração Fiscal Digital  de Retenções"
                  },
                  {
                    "type": "option",
                    "name": "E-Social",
                    "value": "E-Social"
                  },
                  {
                    "type": "option",
                    "name": "GIA – Guia de Informação e Apuração do ICMS",
                    "value": "GIA – Guia de Informação e Apuração do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "GIA — substituição tributária",
                    "value": "GIA — substituição tributária"
                  },
                  {
                    "type": "option",
                    "name": "GIAM-Guia De Informação E Apuração Mensal Do ICMS",
                    "value": "GIAM-Guia De Informação E Apuração Mensal Do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "Livro de Apuração do Lucro Real (Lalur)",
                    "value": "Livro de Apuração do Lucro Real (Lalur)"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Estadual",
                    "value": "Obrigação acessoria Estadual"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Federal",
                    "value": "Obrigação acessoria Federal"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Municipal",
                    "value": "Obrigação acessoria Municipal"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP- Pedido de Restituição, Ressarcimento ou Reembolso",
                    "value": "PER/DCOMP- Pedido de Restituição, Ressarcimento ou Reembolso"
                  },
                  {
                    "type": "option",
                    "name": "SINTEGRA – Sistema Integrado de Informações sobre Operações Interestaduais",
                    "value": "SINTEGRA – Sistema Integrado de Informações sobre Operações Interestaduais"
                  },
                  {
                    "type": "option",
                    "name": "SPED fiscal – Bloco H",
                    "value": "SPED fiscal – Bloco H"
                  },
                  {
                    "type": "option",
                    "name": "SPED fiscal – Bloco K",
                    "value": "SPED fiscal – Bloco K"
                  },
                  {
                    "type": "option",
                    "name": "VAF- Valor Adicionado Fiscal",
                    "value": "VAF- Valor Adicionado Fiscal"
                  },
                  {
                    "type": "option",
                    "name": "Outras obrigações acessórias que não estão discriminadas na lista",
                    "value": "Outras obrigações acessórias que não estão discriminadas na lista"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          }
        ]
      },
      {
        "label": "Motivos da retificação",
        "name": "motivos_da_retifica_o",
        "type": "textArea",
        "validators": [Validators.required, Validators.maxLength(1000)]
      },
      {
        "label": "Prazo para NBS retificar",
        "name": "prazo_para_nbs_retificar",
        "type": "date",
        "validators": Validators.required
      },
      {
        "label": "Notificações (anexar)",
        "name": "notifica_es_anexar",
        "type": "attachment",

      },
      {
        "label": "Cópia da obrigação acessoria",
        "name": "c_pia_da_obriga_o_acessoria",
        "type": "attachment",
        "validators": Validators.required
      },
      {
        "label": "link_3",
        "name": "link_3",
        "type": "textArea",
        "validators": [Validators.required, Validators.maxLength(1000)]
      },
      {
        "label": "Nome do Solicitante",
        "name": "nome_do_solicitante",
        "type": "text",
        "validators": [Validators.required, Validators.maxLength(1000)]
      },
      {
        "label": "E-mail do Solicitante",
        "name": "e_mail_do_solicitante",
        "type": "email",
        "validators": Validators.required
      },
      {
        "label": "E-mail 2",
        "name": "e_mail_2",
        "type": "email"
      },
      {
        "label": "E-mail 3",
        "name": "e_mail_3",
        "type": "email"
      },
      {
        "label": "E-mail 4",
        "name": "e_mail_4",
        "type": "email"
      },
      {
        "label": "Analista responsável pela retificação-NBS",
        "name": "analista_respons_vel_pela_retifica_o_nbs",
        "type": "text",
        "validators": [Validators.required, Validators.maxLength(1000)]
      },
      {
        "label": "E-mail fiscal",
        "name": "e_mail_fiscal",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "agrosema.fiscal  ",
            "value": "agrosema.fiscal  ",
            "fields": [
              {
                "label": "E-mail NBS 2",
                "name": "e_mail_nbs_2",
                "type": "email"
              }
            ]
          },
          {
            "type": "option",
            "name": "agrichem.fiscal ",
            "value": "agrichem.fiscal ",
            "fields": [
              {
                "label": "E-mail NBS 2",
                "name": "e_mail_nbs_2",
                "type": "email"
              }
            ]
          },
          {
            "type": "option",
            "name": "nutrien.fiscal",
            "value": "nutrien.fiscal",
            "fields": [
              {
                "label": "E-mail NBS 2",
                "name": "e_mail_nbs_2",
                "type": "email"
              }
            ]
          },
          {
            "type": "option",
            "name": "tecagro.fiscal",
            "value": "tecagro.fiscal",
            "fields": [
              {
                "label": "E-mail NBS 2",
                "name": "e_mail_nbs_2",
                "type": "email"
              }
            ]
          }
        ],
        "type": "select"
      },

      {
        "label": "E-mail NBS 3",
        "name": "e_mail_nbs_3",
        "type": "email"
      },
      {
        "label": "Observação",
        "name": "observa_o",
        "type": "textArea",
        "validators": [Validators.required, Validators.maxLength(1000)]
      },
      {
        "type": "buttonSubmit",
        "name": "Enviar"
      }
    ]
  }
}





